import styled, { keyframes } from 'styled-components/macro';
import { color } from '../ui/config';
import { flex } from '../ui/helper';

const KeyframesMoveHorizontal = keyframes`
    0% { transform: translateX(-100%) }
    100% { transform: translateX(100%) }
`;

export const Skeleton = styled.div`
  overflow: hidden;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    display: block;

    background-image: linear-gradient(
      95deg,
      transparent 10%,
      ${color.skeleton.shimmer} 40%,
      ${color.skeleton.shimmer} 60%,
      transparent 90%
    );
    background-size: 100%;
    pointer-events: none;

    opacity: 1;
    mix-blend-mode: overlay;
    animation: 1.3s infinite cubic-bezier(0.4, 0, 0.6, 1) ${KeyframesMoveHorizontal};
  }
`;

export const SkeletonBox = styled.span`
  display: block;
  border-radius: 4px;
  background: ${color.skeleton.base};
`;

export const SkeletonHero = styled.div`
  width: 100%;
  height: 17rem;
  margin-bottom: 3rem;
  background: ${color.skeleton.base};
`;

export const SkeletonIntroduction = styled(SkeletonBox)`
  width: 80%;
  height: 1.5rem;
  margin-bottom: 0.5rem;

  &:nth-child(2) {
    width: 75%;
  }
  &:nth-child(3) {
    width: 78%;
  }
  &:nth-child(4) {
    width: 72%;
  }
`;

export const SkeletonH1 = styled(SkeletonBox)`
  width: 90%;
  height: 2.2rem;
  margin-bottom: 0.4rem;

  &:nth-child(2) {
    width: 95%;
  }
`;

export const SkeletonH2 = styled(SkeletonBox)<{ fullsize?: boolean }>`
  width: ${props => (props.fullsize ? '100%' : '80%')};
  height: 1.5rem;
  margin-bottom: 1rem;
`;

export const SkeletonH3 = styled(SkeletonBox)`
  width: 90%;
  height: 1.3rem;
  margin-bottom: 1rem;
`;

export const SkeletonLine = styled(SkeletonBox)<{ small?: boolean; li?: boolean }>`
  width: 95%;
  height: ${props => (props.small ? '1.1rem' : '1.3rem')};
  margin-bottom: 0.3rem;
  ${props => props.li && 'margin-top: 1rem'};

  &:nth-child(2) {
    width: 98%;
  }

  &:nth-child(3) {
    width: 93%;
  }

  &:nth-child(4) {
    width: 90%;
  }
`;

export const SkeletonBreadcrumbs = styled(SkeletonBox)`
  width: 40%;
  height: 1.1rem;
  margin: 0.3rem 0 0.4rem;
`;

export const SkeletonVideo = styled(SkeletonBox)`
  ${flex('row', 'center', 'center')}
  width: 100%;
  padding-bottom: 56.25%; // 16/9
`;

export const SkeletonLi = styled(SkeletonBox)`
  width: 85%;
  height: 1rem;
  margin-top: 1rem;

  &:nth-child(2) {
    width: 98%;
  }

  &:nth-child(3) {
    width: 93%;
  }

  &:nth-child(4) {
    width: 90%;
  }
`;

export const SkeletonCategoryCard = styled(SkeletonBox)`
  width: 100%;
  padding-top: 66%;
`;

/*
  
// ---- animation ----
$skeleton--fx-duration: .2s;

.skeleton-transition-group {
    width: 100%;
}

%skeleton-transition {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, 0);
    transition: opacity $skeleton--fx-duration;
}

.skeleton-transition-enter {
    transition: opacity $skeleton--fx-duration;
    opacity: 0;
}

.skeleton-transition-exit {
    @extend %skeleton-transition;
    opacity: 1;
}

.skeleton-transition-enter-active {
    opacity: 1;
}

.skeleton-transition-exit-active {
    opacity: 0;
}
*/
