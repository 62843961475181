import React from 'react';
import { Skeleton, SkeletonH1, SkeletonH2, SkeletonLine } from '../skeleton/skeleton-styles';
import { Container, FlexCol, FlexRow, Section, SubSection } from '../ui/modules/grid';

export const CategorySkeleton = () => {
  return (
    <Skeleton>
      <Container>
        <Section first>
          <SkeletonH1 className="m-bottom_xl" />

          {[1, 2, 3, 4].map(n => (
            <SubSection key={n}>
              <SkeletonH2 />
              <FlexRow explicitCols>
                <FlexCol width="30%">
                  <SkeletonLine small />
                </FlexCol>
              </FlexRow>
              <SkeletonLine />
              <SkeletonLine />
              <SkeletonLine />
              <SkeletonLine />
            </SubSection>
          ))}
        </Section>
      </Container>
    </Skeleton>
  );
};
