import React, { HTMLProps } from 'react';
import styled, { css } from 'styled-components/macro';
import { Language } from './i18n.types';

interface IFlagProps extends HTMLProps<SVGElement> {
  selected?: boolean;
  small?: boolean;

  crossOrigin?: any;
  ref?: any;
  as?: any;
}

export const FlagSvg = styled.svg<IFlagProps>`
  display: block;
  width: 30px;
  height: 30px;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;

  ${props => props.small && 'transform: scale(.7)'};

  ${props =>
    props.selected === true &&
    css`
      opacity: 0.5;
      cursor: default;
    `}
`;

export const FlagSum = styled(FlagSvg).attrs({ as: 'div', children: '∑' })`
  text-align: center;
`;

export const FlagEn: React.FC<IFlagProps> = props => {
  return (
    <FlagSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30" {...props}>
      <desc>Flag of the United Kingdom</desc>
      <clipPath id="s">
        <path d="M0,0 v30 h60 v-30 z" />
      </clipPath>
      <clipPath id="t">
        <path d="M30,15 h30 v15 z v15 h-30 z h-30 v-15 z v-15 h30 z" />
      </clipPath>
      <g clipPath="url(#s)">
        <path d="M0,0 v30 h60 v-30 z" fill="#012169" />
        <path d="M0,0 L60,30 M60,0 L0,30" stroke="#fff" strokeWidth="6" />
        <path d="M0,0 L60,30 M60,0 L0,30" clipPath="url(#t)" stroke="#C8102E" strokeWidth="4" />
        <path d="M30,0 v30 M0,15 h60" stroke="#fff" strokeWidth="10" />
        <path d="M30,0 v30 M0,15 h60" stroke="#C8102E" strokeWidth="6" />
      </g>
    </FlagSvg>
  );
};

export const FlagDe: React.FC<IFlagProps> = props => {
  return (
    <FlagSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5 3" {...props}>
      <desc>Flag of Germany</desc>
      <rect id="black_stripe" width="5" height="3" y="0" x="0" fill="#000" />
      <rect id="red_stripe" width="5" height="2" y="1" x="0" fill="#D00" />
      <rect id="gold_stripe" width="5" height="1" y="2" x="0" fill="#FFCE00" />
    </FlagSvg>
  );
};

export const getFlagIconByLanguage = (lang: Language, props?: IFlagProps) => {
  switch (lang) {
    case Language.None:
      return <FlagSum />;
    case Language.German:
      return <FlagDe {...props} />;
    case Language.English:
      return <FlagEn {...props} />;
  }
};
