import React, { HTMLProps } from 'react';
import styled from 'styled-components/macro';
import { Meta } from '../ui/atoms';
import { StyledLinkDecent } from '../ui/components/StyledLink';
import { color } from '../ui/config';
import { IBreadcrumbDTO } from './helppage.types';

interface IBreadcrumbContainerProps extends HTMLProps<HTMLDivElement> {
  breadcrumbs: IBreadcrumbDTO[];
}

const BreadcrumbContainer: React.FC<IBreadcrumbContainerProps> = ({ breadcrumbs, ...props }) => (
  <div {...props}>
    {breadcrumbs?.map((b, i) => (
      <Breadcrumb key={b.Id}>
        <BreadcrumbIcon />
        <Meta small>
          {i === breadcrumbs.length - 1 ? (
            <BreadcrumbText>{b.Name}</BreadcrumbText>
          ) : (
            <BreadcrumbLink to={`/${b.Type}/${b.Id}`}>{b.Name}</BreadcrumbLink>
          )}
        </Meta>
      </Breadcrumb>
    ))}
  </div>
);

export default BreadcrumbContainer;

const Breadcrumb = styled.div`
  display: inline-block;
`;

const BreadcrumbIcon = styled('i').attrs({ className: 'fa fa-chevron-right' })`
  display: inline-block;
  font-size: 0.7rem;
  color: ${color.icon.meta};
`;

const BreadcrumbText = styled.div`
  padding: 0 0.3rem;
`;

const BreadcrumbLink = styled(StyledLinkDecent)`
  padding: 0 0.3rem;
`;
