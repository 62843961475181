import React from 'react';
import {Footer} from './Footer';
import {PageHeader} from './PageHeader';

interface IPageLayoutProps {}

const PageLayout: React.FC<IPageLayoutProps> = ({ children }) => {
  return (
    <main>
      <PageHeader />
      <div style={{minHeight: 'calc(100vh - 73px)'}}>{children}</div>
      <Footer />
    </main>
  );
};

export default PageLayout;
