import styled, {css} from "styled-components/macro";
import {DeviceType} from "./config";

// ---- SNIPPETS ----

export const oneLine = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// ---- COMPONENTS ----

export const AspectRatioWrapper = styled.div<{height: string}>`
  display: block;
  padding-top: ${props => props.height};
  color: inherit;
`

export const AspectRatioContent = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

// ---- FUNCTIONS ----

/**
 * Insert flex css properties (display, flex-direction, align-items, justify-content, flex-wrap)
 * @param direction
 * @param alignItems
 * @param justifyContent
 * @param wrap
 */
export const flex = (direction: string = 'row', alignItems: string = 'center', justifyContent: string = 'flex-start', wrap: string = 'nowrap') => `
    display: flex;
    flex-direction: ${direction};
    align-items: ${alignItems};
    justify-content: ${justifyContent};
    flex-wrap: ${wrap};
`;

/**
 * Insert transition property value for n css props.
 * Note: Doesn't generate line-end-semicolon!
 * Example:
 *  transition('.3s', 'opacity', 'visibility') -> 'transition: opacity .3s, visibility .3s'
 *  transition('.3s .1s', 'opacity', 'visibility') -> 'transition: opacity .3s .1s, visibility .3s .1s'
 * @param animationTime animation time, can also contain animation timeout (e.g. '.2s .05s')
 * @param props css properties to animate
 */
export const transition = (animationTime: string, ...props: string[]) => 'transition: ' + props.map(p => `${p} ${animationTime}`).join(', ');

/**
 * Generate media-query header for specific breakpoint.
 * Example:
 * media(DeviceType.MobileM) -> @media (min-width: 375px)
 *
 * Example with styled-components:
 * styled.div`
 *  ${media(DeviceType.laptop)} {
 *    flex-direction: row;
 *  }
 * `;
 * @param breakpoint (min-width)
 */
export const media = (breakpoint: DeviceType) => `@media (min-width: ${breakpoint})`
