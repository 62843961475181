import React from 'react';
import { FlexRow } from '../ui/modules/grid';
import { FlagDe, FlagEn } from './flags';
import { Language } from './i18n.types';
import { useI18n } from './I18nContext';

export const LanguageSwitcher = () => {
  const { lang, setLang } = useI18n('');

  const changeLang = (code: Language) => {
    if (code === lang) return;
    setLang(code);
    window.scrollTo(0, 0);
  };

  return (
    <FlexRow>
      <FlagDe onClick={() => changeLang(Language.German)} selected={lang === Language.German} />
      <FlagEn onClick={() => changeLang(Language.English)} selected={lang === Language.English} />
    </FlexRow>
  );
};