import React, { HTMLProps, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { urlMedia } from '../../config';
import { flex } from '../../ui/helper';

interface IVideoComponentProps extends HTMLProps<HTMLVideoElement> {
  video: string;
  as?: any;
}

const VideoComponent: React.FC<IVideoComponentProps> = ({ video, ...props }) => {
  const [wasClicked, setWasClicked] = useState(false);
  return (
    <VideoWrapper clicked={wasClicked}>
      <VideoOverlay visible={!wasClicked}>
        <VideoPlayIcon />
      </VideoOverlay>
      <Video
        src={urlMedia + video}
        controls
        preload="true"
        onPlay={() => setWasClicked(true)}
        {...props}
      />
    </VideoWrapper>
  );
};

const VideoWrapper = styled.div<{ clicked: boolean }>`
  display: block;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  text-align: center;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.2);

  ${(props) => !props.clicked && 'cursor: pointer'};

  &:before {
    max-width: 100%;
    content: '';
    width: 1px;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    margin-left: -1px;
  }
`;

const VideoOverlay = styled.div<{ visible: boolean }>`
  ${flex('row', 'center', 'center')};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow: hidden;
  pointer-events: none;

  background: rgba(0, 0, 0, 0.7);

  opacity: 0;
  visibility: hidden;
  transition: opacity 0.1s, visibility 0.1s;

  ${(props) =>
    props.visible &&
    css`
      opacity: 1;
      visibility: visible;
    `};
`;
const VideoPlayIcon = styled('i').attrs({ className: 'fa fa-play' })`
  font-size: 5rem;
  color: hsla(0, 0%, 100%, 0.9);
`;
const Video = styled.video`
  display: inline-block;
  max-height: 100%;
  max-width: 100%;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.2);
  vertical-align: middle;
`;

export default VideoComponent;
