import React, { HTMLProps } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { dontForward } from '../../core/util';
import { color } from '../config';

interface ILinkSpanProps extends HTMLProps<HTMLAnchorElement> {
  internal?: boolean;
  external?: boolean; // if true, not only the icon will be changed, but instead of a <Link> tag we'll render an <a> tag
  download?: boolean;
  invert?: boolean;
  noUnderline?: boolean;
  noArrow?: boolean;
}

export interface IStyledLinkProps extends ILinkSpanProps {
  asBlock?: boolean;
  to?: string;
  href?: string;
}

export const StyledLink: React.FC<IStyledLinkProps> = ({ to = '', children, ...props }) => {
  let _props = { to, ...props } as any;
  if (props.external || props.href) {
    _props.as = 'a';

    if (props.external) {
      _props.target = '_blank';
      _props.rel = 'noopener noreferrer';
      _props.href = props.href || to;
      _props.to = undefined;
    }
  }

  const linkSpanProps = {
    internal: props.internal,
    external: props.external,
    download: props.download,
    invert: props.invert,
    noUnderline: props.noUnderline,
    noArrow: props.noArrow,
  };

  return (
    <StyledLinkWrapper {..._props}>
      <StyledLinkSpan {...linkSpanProps}>{children}</StyledLinkSpan>
    </StyledLinkWrapper>
  );
};

const StyledLinkSpan = styled.span<ILinkSpanProps>`
  padding-right: 0.1em;
  background-image: linear-gradient(to bottom, ${color.brand.primary} 0%, ${color.brand.primary} 100%);
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 0;
  transition: color 0.1s, background-size 0.1s;

  // extend element on hover with :before for better click-box
  &:before,
  &:after {
    content: '';
    display: ${props => (props.noArrow ? 'none' : 'block')};
    position: absolute;
    bottom: 0;
    right: 0;
    width: 2em;
    height: 2.2em;
    z-index: 1000;

    font: 700 0.6em/2.1em 'Font Awesome 5 Free', monospace;

    // animated properties
    opacity: 0;
    visibility: hidden;
    transform: translateX(60%);
    transition: transform 0.15s, opacity 0.15s, visibility 0.15s;
  }

  // slide-out arrow
  &:after {
    content: '\f061';
    right: -1em;
    height: 2em;

    margin: 0 0.5em;
    text-align: center;

    border-radius: 1000px;
    background: ${color.brand.primary};

    // animated properties
    opacity: 0;
    visibility: hidden;
    transform: translateX(60%);
    transition: transform 0.15s, opacity 0.15s, visibility 0.15s;
  }

  // ---- special cases ----
  // use hashtag for internal links
  ${props =>
    props.internal &&
    css`
      &:after {
        content: '\f292'; // hashtag
      }
    `}
`;

const StyledLinkWrapper = styled(Link).withConfig(
  dontForward('internal', 'external', 'download', 'invert', 'asBlock', 'noUnderline', 'noArrow')
)<IStyledLinkProps>`
  display: ${props => (props.asBlock ? 'block' : 'inline')};
  margin-left: -0.1em;
  font: inherit;
  text-decoration: inherit;
  color: ${props => (props.noUnderline ? color.text.primary : color.brand.primary)};
  transition: color 0.1s;

  &:hover ${StyledLinkSpan} {
    color: ${color.text.light};
    background-size: 100% 100%;

    &:before,
    &:after {
      opacity: 1;
      visibility: visible;
      color: ${color.text.light};

      // with a slight delay show and place the arrow
      transform: translateX(100%);
      transition: transform 0.15s 0.1s, opacity 0.15s 0.1s, visibility 0.15s 0.1s;
    }

    &:after {
      // different arrow transform for external and download
      ${props => {
        if (props.download) return 'transform: translateX(100%) rotate(90deg)';
        if (props.external) return 'transform: translateX(100%) rotate(-45deg)';
      }}
    }
  }
`;

// Using same props as StyledLink to make it easily replacable
interface IStyledLinkDecentProps {
  noUnderline?: boolean;
  invert?: boolean;
  asBlock?: boolean;
  to?: string;
}

const styledLinkDecentContent = ({ asBlock, noUnderline }: IStyledLinkDecentProps) => css`
  ${asBlock && 'display: block'};
  font: inherit;
  text-decoration: none;
  color: ${noUnderline ? 'inherit' : color.brand.primary};

  transition: color 0.15s;

  &:hover {
    color: ${color.brand.primary};
    text-decoration: underline;
  }
`;

const dontForwardLinkProps = dontForward('noUnderline', 'invert', 'asBlock');

export const StyledLinkDecent = styled(Link).withConfig(dontForwardLinkProps)<IStyledLinkDecentProps>`
  ${styledLinkDecentContent}
`;
export const StyledADecent = styled.a.withConfig(dontForwardLinkProps)<IStyledLinkDecentProps>`
  ${styledLinkDecentContent}
`;
