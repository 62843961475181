import React, { HTMLProps, useState } from 'react';
import { urlMedia } from '../../config';
import styled, { css } from 'styled-components/macro';
import { color } from '../../ui/config';
import { flex } from '../../ui/helper';

interface IImageComponentProps extends HTMLProps<HTMLImageElement> {
  crossOrigin?: '' | 'anonymous' | 'use-credentials' | undefined; // doesn't work out of the box
  src: string;
  description: string;
  as?: any;
}

const ImageComponent: React.FunctionComponent<IImageComponentProps> = ({ src, description, ...props }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleModalClick = (ev: any) => {
    if (ev.target === ev.currentTarget) setModalVisible(false);
  };

  return (
    <StyledImage>
      <Img src={urlMedia + src} alt={description} {...props} onClick={() => setModalVisible(true)} />
      <Caption>{description}</Caption>

      <ImgModal visible={modalVisible} onClick={handleModalClick}>
        <ModalImg src={urlMedia + src} alt={description} visible={modalVisible} />
        <ModalClose onClick={() => setModalVisible(false)} />
      </ImgModal>
    </StyledImage>
  );
};

const StyledImage = styled.div`
  display: block;
  max-width: 100%;
  margin-bottom: 2rem;
  padding: 1rem 1rem 0.3rem;
  background: ${color.grey.bg};
`;

const Img = styled.img`
  max-width: 100%;
  cursor: pointer;
`;

const Caption = styled.div`
  content: attr(alt);
  margin-top: 0.2rem;
  font-size: 0.9rem;
  color: ${color.text.secondary};
`;

const ImgModal = styled.div<{ visible: boolean }>`
  ${flex('row', 'center', 'center')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9000;

  height: 100%;
  background: rgba(0, 0, 0, 0.9);

  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s, visibility 0.15s;

  ${props =>
    props.visible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

const ModalImg = styled.img<{ visible: boolean }>`
  display: block;
  width: auto;
  height: auto;
  max-width: 90%;
  max-height: 90%;

  transform: scale(0.95);
  transition: transform 0.15s;

  ${props =>
    props.visible &&
    css`
      transform: scale(1);
    `}
`;

const ModalClose = styled('i').attrs({ className: 'fa fa-times' })`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.85);
  cursor: pointer;
`;
export default ImageComponent;
