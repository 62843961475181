import React, { useContext, useEffect, useState } from 'react';
import { getInitialLang, persistLangSetting, translate } from './i18n.helper';
import { TranslationLang, II18nContext, Language, GLOBAL_FALLBACK_LANG, FnTranslate } from './i18n.types';
import { fetchLang } from './I18nResource';

/**
 * Actual react context for i18n.
 * Only used by I18nProvider, so it's used as a singleton.
 */
const I18nContext = React.createContext<II18nContext>({
  lang: GLOBAL_FALLBACK_LANG,
  setLang: () => null,
  translations: {},
});

/**
 * Render a stateful instance of I18nContext.Provider. Used in index.tsx
 * @param props
 */
export const I18nContextProvider: React.FC = ({ children }) => {
  const [lang, _setLang] = useState<Language>(getInitialLang());
  const [translations, setTranslations] = useState<TranslationLang>({});

  const setLang = (lang: Language) => {
    _setLang(lang);
    persistLangSetting(lang);
  };

  useEffect(() => {
    setTranslations(fetchLang(lang));

    const windowAsAny = window as any;
    windowAsAny.UC_UI?.updateLanguage(lang);
  }, [lang]);

  return <I18nContext.Provider value={{ lang, setLang, translations }}>{children}</I18nContext.Provider>;
};

/**
 * Hook to use i18n in other components
 * @param props
 */
export const useI18n = (prefix: string) => {
  const { translations, lang, setLang } = useContext(I18nContext);
  const [t, setT] = useState<FnTranslate>(() => () => ''); // double function notation as react expects a function to return the value (which is a function object in our case)

  useEffect(() => {
    const _t: FnTranslate = (key, ...args) => translate(translations, `${prefix}.${key}`, ...args);
    setT(() => _t);
  }, [translations, lang, prefix]);

  return { t, lang, setLang };
};
