import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BasicState, useStateMachine } from '../core/useStateMachine';
import { Language } from '../i18n/i18n.types';
import { useI18n } from '../i18n/I18nContext';
import { EmptyState, H1, H2, H3, Meta } from '../ui/atoms';
import { Modal, ModalButton, ModalButtons } from '../ui/components/Modal';
import { StyledLink } from '../ui/components/StyledLink';
import { Textbox } from '../ui/modules/controls/Textbox';
import { Container, Section, SubSection } from '../ui/modules/grid';
import { RatingComments } from './RatingComments';
import { RatingGraph } from './RatingGraph';
import { RatingResource } from './RatingResource';
import { IArticleRatingGetDTO, II18nDtoContainer } from './ratings.types';

const transformArticleRatingsDTOs = (dtos: IArticleRatingGetDTO[]) => {
  const i18DtoContainers = dtos.reduce((containers, dto) => {
    let container = containers.find(c => c.id === dto.ArticleId);
    if (!container) {
      container = {
        id: dto.ArticleId,
        title: dto.ArticleTitle,
        comments: [],
        byLang: [],
      };
      containers.push(container);
    }

    container.comments = [...container.comments, ...dto.Feedbacks];
    container.byLang.push(dto);

    return containers;
  }, [] as II18nDtoContainer<IArticleRatingGetDTO>[]);

  // add total ratings (= not split by language) for every helppage
  return i18DtoContainers.map(container => ({
    ...container,
    byLang: [
      // add the sum-rating
      container.byLang.reduce(
        // reduce all ratings in this language to one dto
        (dtoMerged, dto) => {
          dtoMerged.CountHelpful += dto.CountHelpful;
          dtoMerged.CountNotHelpful += dto.CountNotHelpful;
          return dtoMerged;
        },
        { Language: Language.None, CountHelpful: 0, CountNotHelpful: 0 } as IArticleRatingGetDTO
      ),
      // add other language-specific dto-containers back to the list
      ...container.byLang,
    ],
  }));
};

export const RatingsAdminView = () => {
  const [ratings, setRatings] = useState<II18nDtoContainer<IArticleRatingGetDTO>[]>([]);
  const [pwModalOpen, setPwModalOpen] = useState(false);
  const [pw, setPw] = useState('');
  const { state, nextState, msg } = useStateMachine(BasicState.Initial);
  const { lang, t } = useI18n('ratings.RatingsAdminView');
  const pwRef = useRef<HTMLInputElement>(null);

  const fetchRatings = useCallback(
    (_pw: string) => {
      nextState(BasicState.Loading, 'loading');
      RatingResource.read(lang, _pw || '')
        // RatingResource.read(lang, 'TestMe') // TODO
        .then(ratings => {
          setRatings(transformArticleRatingsDTOs(ratings));
          nextState(BasicState.Success);
        })
        .catch(err => {
          if (err.status === 401) return nextState(BasicState.Error, 'err_wrongPassword');
          return nextState(BasicState.Error, 'err_serviceError');
        });
    },
    [lang, nextState]
  );

  const handleModalSubmit = (ev: any) => {
    ev.preventDefault();
    fetchRatings(pw);
  };

  const onModalClose = () => {
    nextState(BasicState.Error, 'err_canceled');
  };

  useEffect(() => {
    if (pw) return fetchRatings(pw);
    nextState(BasicState.Initial, 'waitingForPassword');
    setPwModalOpen(true);
    setTimeout(() => pwRef.current?.focus(), 100); // set timeout to wait for modal visible
  }, [lang, nextState, fetchRatings]); // eslint-disable-line react-hooks/exhaustive-deps

  const getContent = () => {
    switch (state) {
      case BasicState.Initial:
        return (
          <div>
            <EmptyState>{t(msg)}</EmptyState>
            <Modal open={pwModalOpen} onClose={onModalClose}>
              <H3 className="m-bottom_sm">{t('headline_enterPassword')}</H3>
              <form onSubmit={handleModalSubmit}>
                <Textbox label={t('label_pw')} value={pw} onChange={(ev: any) => setPw(ev.target.value)} ref={pwRef} />
                <ModalButtons>
                  <ModalButton type="submit">{t('btn_submitPw')}</ModalButton>
                  <ModalButton type="button" tertiary onClick={onModalClose}>
                    {t('btn_cancel')}
                  </ModalButton>
                </ModalButtons>
              </form>
            </Modal>
          </div>
        );

      case BasicState.Loading:
      case BasicState.Error:
        return <EmptyState>{t(msg)}</EmptyState>;

      case BasicState.Success:
        return ratings.map(i18nDtoContainer => (
          <SubSection key={i18nDtoContainer.id}>
            {/* Title + Link to Helppage */}
            <H2>
              {i18nDtoContainer.title || t('headline_articleNotAvailableInCurrentLang')}{' '}
              <StyledLink to={`/helppage/${i18nDtoContainer.id}`}>
                <Meta small>/{i18nDtoContainer.id}</Meta>
              </StyledLink>
            </H2>

            {/* Ratings */}
            {i18nDtoContainer.byLang.map(rating => (
              <RatingGraph key={rating.Language} rating={rating} />
            ))}

            {/* Comments */}
            <RatingComments i18nDtoContainer={i18nDtoContainer} />
          </SubSection>
        ));
    }
  };

  return (
    <Container narrow>
      <Section first>
        <H1>{t('headline_feedbackByHelppage')}</H1>
        {getContent()}
      </Section>
    </Container>
  );
};
