import React, { useState } from 'react';
import { BasicState, useStateMachine } from '../core/useStateMachine';
import { StatefulButton } from '../ui/components/StatefulButton';
import { Button } from '../ui/modules/controls/Button';
import { UiControlSize } from '../ui/modules/controls/controls.types';
import { Textbox } from '../ui/modules/controls/Textbox';
import { FlexRow } from '../ui/modules/grid';
import { VariantTable } from './VariantTable';

export const UiButtons = () => {
  const [label, setText] = useState('Mehr anzeigen');
  const [icon, setIcon] = useState('fa-search');
  const { state, nextState } = useStateMachine(BasicState.Initial);

  const base = { children: label };
  const group = [{}, { secondary: true }]; // distinguish primary/secondary buttons
  const row = [{ size: UiControlSize.Small }, {}, { size: UiControlSize.Large }]; // show different sizes in rows
  const column = [{}, { withArrow: true }, { icon: icon }, { icon: icon, children: null }]; // show default / with arrow / with icon / icon only in one column
  const variantTableProps = { base, group, row, column, el: Button };

  return (
    <div>
      {/* inputs for setting text and icon */}
      <FlexRow spaced>
        <Textbox className="m-right" label="Button Text" value={label} onChange={(ev: any) => setText(ev.target.value)} />
        <Textbox label="Button Icon" value={icon} onChange={(ev: any) => setIcon(ev.target.value)} />
      </FlexRow>
      <VariantTable {...variantTableProps} />
      <FlexRow spaced>
        <Button secondary size={UiControlSize.Small} onClick={() => nextState(BasicState.Initial)}>
          initial
        </Button>
        <Button secondary size={UiControlSize.Small} onClick={() => nextState(BasicState.Loading)}>
          loading
        </Button>
        <Button secondary size={UiControlSize.Small} onClick={() => nextState(BasicState.Success)}>
          success
        </Button>
        <Button secondary size={UiControlSize.Small} onClick={() => nextState(BasicState.Error)}>
          error
        </Button>
      </FlexRow>
      <StatefulButton state={state} labels={{}}>
        ich bin stateful
      </StatefulButton>
    </div>
  );
};
