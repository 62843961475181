import React, { HTMLProps } from 'react';
import styled, { css } from 'styled-components';
import { BasicState } from '../../core/useStateMachine';
import { transition } from '../helper';
import { Button, ButtonIcon } from '../modules/controls/Button';
import { FlexRow } from '../modules/grid';
import { Spinner, SpinnerSize } from './Spinner';

type StatefulLabels = {
  [state: string]: string;
};

interface IStatefulButtonProps extends HTMLProps<HTMLButtonElement> {
  state: BasicState;
  labels: StatefulLabels;
  secondary?: boolean;
  as?: any;
}

export const StatefulButton: React.FC<IStatefulButtonProps> = ({
  state,
  labels,
  secondary,
  children,
  type = 'button',
  ...props
}) => {
  return (
    <Button secondary={secondary} type={type} {...props}>
      <ButtonText visible={state === BasicState.Initial} persistent>
        {children}
      </ButtonText>
      <ButtonText visible={state === BasicState.Loading}>
        <Spinner light size={SpinnerSize.Button} style={{ marginRight: '.7rem' }} />
        {labels[BasicState.Loading] || 'loading'}
      </ButtonText>
      <ButtonText visible={state === BasicState.Success}>
        <ButtonIcon className="fa fa-check m-right_sm" />
        {labels[BasicState.Success] || 'success'}
      </ButtonText>
      <ButtonText visible={state === BasicState.Error}>
        <ButtonIcon className="fa fa-times m-right_sm" />
        {labels[BasicState.Error] || 'error'}
      </ButtonText>
    </Button>
  );
};

// styled components

const ButtonText = styled(FlexRow).attrs({ align: 'center', justify: 'center' })<{ visible: boolean; persistent?: boolean }>`
  position: ${props => (props.persistent ? 'relative' : 'absolute')};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  opacity: 0;
  visibility: hidden;
  transform: scale(0.8);
  ${transition('.15s', 'opacity', 'visibility', 'transform')};
  ${props =>
    props.visible &&
    css`
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    `};
`;
