import { parseCmsHtml } from '../core/util';
import { GLOBAL_FALLBACK_LANG, Language, TranslationLang } from './i18n.types';

function parseLanguage(code: string): Language | null {
  if (Language.German === code) return Language.German;
  if (Language.English === code) return Language.English;
  const split = code.split('-');
  if (split.length > 1) return parseLanguage(split[0]);
  return null;
}

export const getInitialLang = () => {
  // try to get language stored in browser
  let restoredLang = window.localStorage.getItem('helpcenter-lang');
  // use browser language
  if (!restoredLang) restoredLang = navigator.language;
  // try to parse language
  if (restoredLang) {
    const parsed = parseLanguage(restoredLang);
    if (parsed) return parsed;
  }
  return GLOBAL_FALLBACK_LANG;
};

export const persistLangSetting = (lang: Language) => {
  window.localStorage.setItem('helpcenter-lang', lang);
};

/**
 * Get translation by key
 * @param translations set of key-value-pairs with translations by key
 * @param key translation key to search for
 * @param replacer object with templating data.
 *
 * @example translate({headline_foo: 'Hello {name}'}, 'headline_foo', {name: 'John Doe'}) -> 'Hello John Doe'
 */
export const translate: (translations: TranslationLang, key: string, ...args: any) => string = (translations, key, ...args) => {
  if (JSON.stringify(translations) === '{}') return '';
  if (!translations) return 'LOADING...';
  const text = objectGet(translations, key);
  if (!text) return `<${key}>`;
  if (typeof text === 'function') return text(...args);
  const asHtml = text && text.indexOf('<') > -1 ? parseCmsHtml(text) : text;
  return asHtml;
};

/**
 * Safely access an object property by string.
 * @param object object to get data from
 * @param path dot separated path
 *
 * @example objectGet({foo: {bar: {baz: 43}}}, 'foo.bar.baz') -> 43
 * @example objectGet({foo: {bar: {baz: 43}}}, 'foo.bart.baz') -> null
 */
export const objectGet = (object: any, path: string) => {
  if (!path || !object) return null;
  return path.split('.').reduce((obj, key) => {
    if (!obj) return null;
    return obj[key] || null;
  }, object);
};
