import { useEffect, useState } from 'react';
import { IFeature } from './feature-flag.types';
import { FeatureFlagResource } from './FeatureFlagResource';

/**
 * Returns an IFeature array of disabled feature flags.
 * Used as placeholder before flags are actually fetched from the server.
 * @param names array of feature flag names.
 * @returns an IFeature array containing placeholder features (all set to enabled: false).
 */
const getBlankFeatures = (names: string[]): IFeature[] =>
  names.map(n => ({
    Name: n,
    Enabled: false,
  }));

/**
 * Provides a convenient way to use a given set of feature flags.
 * @param names array of feature-flag names to fetch.
 * @returns an array of features fetched from api in the same order as the flag names were provided.
 */
export const useFeatureFlags = (...names: string[]) => {
  const [flags, setFlags] = useState(getBlankFeatures(names));
  const namesStr = JSON.stringify(names);

  // fetch flags
  useEffect(() => {
    FeatureFlagResource.readMany(names)
      .then(flags => {
        setFlags(flags);
      })
      .catch(err => console.error(err));
  }, [namesStr]); // eslint-disable-line react-hooks/exhaustive-deps

  return flags;
};
