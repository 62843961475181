import React from 'react';
import { Skeleton, SkeletonBreadcrumbs, SkeletonH1, SkeletonVideo, SkeletonLine } from '../skeleton/skeleton-styles';
import { P } from '../ui/atoms';
import { Container, Section, SubSection } from '../ui/modules/grid';

const HelppageSkeleton: React.FC = () => (
  <Skeleton>
    <Container narrow>
      <SkeletonBreadcrumbs />
      <SkeletonH1 />
      <SubSection>
        <SkeletonVideo />
      </SubSection>
      <Section first>
        <P>
          <SkeletonLine />
          <SkeletonLine />
          <SkeletonLine />
          <SkeletonLine />
        </P>
        <P>
          <SkeletonLine />
          <SkeletonLine />
          <SkeletonLine />
          <SkeletonLine />
        </P>
      </Section>
    </Container>
  </Skeleton>
);

export default HelppageSkeleton;
