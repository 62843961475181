import { Language } from '../i18n/i18n.types';
import { CacheFactory } from './CacheFactory';
import { FetchJson, PostJson } from './resource.types';

export function ResourceFactory() {
  const cache = CacheFactory();

  /**
   * Fetch data from an endpoint. Has an automatic caching mechanism.
   * @param url url to fetch from
   * @param lang content language
   * @param init config object (same as second param in fetch)
   */
  const fetchJson: FetchJson = (url, lang, init?) => {
    const cached = cache.read(url, lang);
    if (cached) return cached;

    const fetchConfig = {
      ...init,
      headers: getHeaders(lang, init),
    };
    const promise = fetch(url, fetchConfig)
      .then(res => (res.ok ? res.json() : Promise.reject(res)))
      .then(data => {
        cache.write(url, lang, data);
        return data;
      });

    cache.write(url, lang, promise);
    return promise;
  };

  const postJson: PostJson = (url, lang, init) => {
    const fetchConfig = {
      ...init,
      method: 'post',
      headers: getHeaders(lang, init),
    };
    return fetch(url, fetchConfig).then(res =>
      res.ok
        ? new Promise((resolve, reject) => {
            res
              .json()
              .then(resolve)
              .catch(err => {
                if (err.name === 'SyntaxError') return resolve(res);
                reject(err);
              });
          })
        : Promise.reject(res)
    );
  };

  const getHeaders = (lang: Language, init?: RequestInit) => ({
    ...init?.headers,
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Language: lang,
  });

  return { fetchJson, postJson };
}
