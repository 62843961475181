import React, { HTMLProps } from 'react';
import styled from 'styled-components/macro';
import { getFlagIconByLanguage } from '../i18n/flags';
import { H3 } from '../ui/atoms';
import { color } from '../ui/config';
import { FlexRow } from '../ui/modules/grid';
import { IArticleRatingGetDTO } from './ratings.types';

interface IRatingGraphProps extends HTMLProps<HTMLDivElement> {
  rating: IArticleRatingGetDTO;

  as?: any;
}

export const RatingGraph: React.FC<IRatingGraphProps> = ({ rating, ...props }) => {
  return (
    <FlexRow {...props}>
      {getFlagIconByLanguage(rating.Language)}
      <FeedbackIcon className="far fa-thumbs-up" />
      <H3>{rating.CountHelpful}</H3>

      <StyledRatingGraph>
        <RatingLine color={color.ui.success} width={getLineWidth(rating.CountHelpful, rating.CountNotHelpful)} />
        <RatingLine color={color.ui.error} width={getLineWidth(rating.CountNotHelpful, rating.CountHelpful)} />
      </StyledRatingGraph>

      <H3>{rating.CountNotHelpful}</H3>
      <FeedbackIcon className="far fa-thumbs-down" flippedX />
    </FlexRow>
  );
};

const getLineWidth = (countThis: number, countOthers: number) => {
  return (countThis / (countThis + countOthers)) * 100 + '%';
};

const FeedbackIcon = styled.i<{ flippedX?: boolean }>`
  font-size: 1rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  color: ${color.text.meta};

  ${props => props.flippedX && 'transform: scaleX(-1)'};
`;

const StyledRatingGraph = styled(FlexRow)`
  width: calc(100% - 1rem);
  margin: 1rem 0.5rem;
`;

const RatingLine = styled.div<{ color: string; width: string }>`
  width: ${props => props.width};
  height: 6px;
  background: ${props => props.color};
`;
