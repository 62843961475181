import React, { HTMLProps } from 'react';
import styled from 'styled-components/macro';

interface IVariantTableProps extends HTMLProps<HTMLTableElement> {
  base: object;
  group?: any[];
  row: any[];
  column: any[];
  el: React.FC<any>;
}

export const VariantTable: React.FC<IVariantTableProps> = ({ base, group = [{}], row, column, el, className }) => {
  return (
    <Table className={className}>
      <tbody>
        {group.map(g => {
          const propsGroup = { ...base, ...g };
          return row.map((r, rowIndex) => {
            const propsRow = { ...propsGroup, ...r };
            return (
              <Tr key={rowIndex}>
                {column.map((c, colIndex) => {
                  const propsColumn = { ...propsRow, ...c };
                  return <Td key={colIndex}>{React.createElement(el, propsColumn)}</Td>;
                })}
              </Tr>
            );
          });
        })}
      </tbody>
    </Table>
  );
};

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;
export const Tr = styled.tr``;
export const Td = styled.td`
  vertical-align: top;
  padding: 0.2rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;
