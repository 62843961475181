import React, {HTMLProps} from 'react';
import styled, {keyframes} from 'styled-components/macro';
import {color} from '../config';

// ---- KEYFRAMES ----
/**
 * svg just rotates
 */
const svgAnimation = keyframes`
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
`;

/**
 * circle changes stroke and rotates in a weird manner.
 * Together with the svg-rotate-animation it does fancy things.
 */
const circleAnimation = keyframes`
  0%, 2% {
    stroke-dashoffset: 98;
    transform: rotate(0);
  }
  50% {
    stroke-dashoffset: 25;
    transform: rotate(45deg);
  }
  98%, 100% {
    stroke-dashoffset: 98;
    transform: rotate(360deg);
  }
`;

/**
 * circle changes colors while rotating. TIX needs more brand colors for this one.
 * Animation pre-built but commented outin StyledSpinnerCircle
 */
const circleColorAnimation = keyframes`
  0%, 20% {
    stroke: red;
  }
  25%, 45% {
    stroke: green;
  }
  50%, 70% {
    stroke: blue;
  }
  75%, 95% {
    stroke: orange;
  }
  100% {
    stroke: red;
  }
`;

/**
 * Available Spinner-sizes in px. Extend at own will.
 */
export enum SpinnerSize {
  Button = 22,
  Default = 28
}

/**
 * Styled component for the spinner cycle.
 */
const StyledSpinnerCircle = styled.circle<{ light?: boolean }>`
  max-width: 100px;
  fill: transparent;
  stroke: ${props => props.light ? color.text.light : color.text.primary};
  stroke-width: 12;
  stroke-linecap: round;
  stroke-dasharray: 100;

  transform-origin: 50% 50%;
  animation: 1.4s ease-in-out infinite both ${circleAnimation}; /*, 5.6s ease-in-out infinite ${circleColorAnimation};*/
`;

/**
 * Assembling the unstyled spinner with it's styled circle
 * @param size
 * @param light
 * @param className
 * @param props
 */
const UnstyledSpinner: React.FC<ISpinnerProps> = ({size = SpinnerSize.Default, light, className, ...props}) => (
  <svg className={className}
       width={size}
       viewBox="0 0 100 100"
       xmlns="http://www.w3.org/2000/svg"
       {...props}>
    <StyledSpinnerCircle
      pathLength="100"
      light={light}
      cx="50"
      cy="50"
      r="44"/>
  </svg>);

interface ISpinnerProps extends HTMLProps<SVGElement> {
  size?: SpinnerSize;
  light?: boolean;

  // styled-components prop fix
  as?: any;
  ref?: any;
  crossOrigin?: any;
}

/**
 * Endless spinning circle of death.
 * Available in different sizes via enum SpinnerSize
 */
export const Spinner = styled(UnstyledSpinner)`
  width: ${props => props.width}px;
  height: ${props => props.width}px;
  animation: 2s linear infinite ${svgAnimation};
`;
