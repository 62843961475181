import React, { useState } from 'react';
import { UiControlSize } from '../ui/modules/controls/controls.types';
import { Radio } from '../ui/modules/controls/Radio';
import { RadioGroup } from '../ui/modules/controls/RadioGroup';
import { Textarea } from '../ui/modules/controls/Textarea';
import { Textbox } from '../ui/modules/controls/Textbox';
import { FlexRow } from '../ui/modules/grid';
import { Table, Td, Tr, VariantTable } from './VariantTable';

export const UiInputs = () => {
  const [inputLabel, setInputLabel] = useState('Suchen');

  const base = { label: inputLabel };
  const row = [{ size: UiControlSize.Small }, { size: UiControlSize.Default }, { size: UiControlSize.Large }]; // show different sizes in rows
  const column = [{ animated: false }, {}]; // show animated / non-animated versions in columns
  const variantTableProps = { base, row, column, el: Textbox };

  return (
    <div>
      <FlexRow spaced>
        <Textbox label="Input label" value={inputLabel} onChange={(ev: any) => setInputLabel(ev.target.value)} />
      </FlexRow>
      <VariantTable {...variantTableProps} />
      <Table>
        <tbody>
          <Tr>
            <Td>
              <RadioGroup name="radio-test-1" onValueChange={val => console.log('group change', val)}>
                <Radio value="one" label="option one" />
                <Radio value="two" label="option two" />
              </RadioGroup>
              <RadioGroup horizontal name="radio-test-2" onValueChange={val => console.log('group change', val)}>
                <Radio value="one" label="option one" />
                <Radio value="two" label="option two" className="m-left" />
              </RadioGroup>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Textarea label={inputLabel} />
            </Td>
          </Tr>
        </tbody>
      </Table>
    </div>
  );
};
