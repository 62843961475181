import React, { useEffect, useState } from 'react';
import { HelppageResource } from './HelppageResource';
import ImageComponent from './components/ImageComponent';
import TextComponent from './components/TextComponent';
import BreadcrumbContainer from './BreadcrumbContainer';
import VideoComponent from './components/VideoComponent';
import { useParams } from 'react-router-dom';
import useErrorRedirect from '../error-pages/useErrorRedirect';
import HelppageSkeleton from './HelppageSkeleton';
import SkeletonContainer from '../skeleton/SkeletonContainer';
import { IHelppageDTO, HELPPAGE__IMAGE, HELPPAGE__TEXT } from './helppage.types';
import { EmptyState, H1, H2 } from '../ui/atoms';
import { Container, Section, SubSection } from '../ui/modules/grid';
import { StyledLinkDecent } from '../ui/components/StyledLink';
import { setDocumentTitle } from '../core/util';
import { RatingsSection } from '../ratings/RatingsSection';
import { useI18n } from '../i18n/I18nContext';
import { BasicState, useStateMachine } from '../core/useStateMachine';

interface IHelppageViewProps {}

const HelppageView: React.FC<IHelppageViewProps> = () => {
  const [helppage, setHelppage] = useState<IHelppageDTO>({} as IHelppageDTO);
  const { helppageId } = useParams() as any;
  const { t, lang } = useI18n('helppage.HelppageView');
  const redirectToErrorPage = useErrorRedirect();
  const { state, nextState, msg } = useStateMachine(BasicState.Loading);

  useEffect(() => {
    HelppageResource.read(helppageId, lang)
      .then((data: IHelppageDTO) => {
        // no data returned -> id doesn't exist
        if (!data) return redirectToErrorPage(404);
        // data returned but Umbraco-Title is null -> id exists, but not in this language
        if (data.TitleNav === null) {
          setDocumentTitle('');
          return nextState(BasicState.Error, 'err_notAvailableInCurrentLang');
        }
        setHelppage(data);
        setDocumentTitle(data.TitleNav);
        nextState(BasicState.Success);
      })
      .catch(() => redirectToErrorPage(503));
  }, [helppageId, redirectToErrorPage, lang, nextState]);

  const getContent = () => {
    switch (state) {
      case BasicState.Error:
        return (
          <Section first>
            <EmptyState>{t(msg)}</EmptyState>
          </Section>
        );
      default:
        return (
          <div>
            <BreadcrumbContainer breadcrumbs={helppage.Breadcrumbs} className="m-top_xl" />
            <H1>{helppage.Title}</H1>

            {helppage.Video && (
              <SubSection>
                <VideoComponent video={helppage.Video} />
              </SubSection>
            )}

            <Section first>
              {helppage.Content &&
                helppage.Content.map((c: any) => {
                  switch (c.Type) {
                    case HELPPAGE__IMAGE:
                      return <ImageComponent key={c.Id} src={c.Src} description={c.Description} />;
                    case HELPPAGE__TEXT:
                      return <TextComponent key={c.Id} content={c.Content} />;
                    default:
                      return <pre>{JSON.stringify(c)}</pre>;
                  }
                })}
            </Section>

            {/* RELATED ARTICLES */}
            {!!helppage.RelatedArticles?.length && (
              <Section>
                <H2 className="m-bottom">{t('headline_relatedArticles')}</H2>
                {helppage.RelatedArticles.map(i => (
                  <StyledLinkDecent key={i.Id} to={`/helppage/${i.Id}`} className="m-top_sm" asBlock>
                    {i.Name}
                  </StyledLinkDecent>
                ))}
              </Section>
            )}

            <RatingsSection helppage={helppage} />
          </div>
        );
    }
  };

  return (
    <SkeletonContainer skeleton={<HelppageSkeleton />} showContent={state !== BasicState.Loading}>
      <Container narrow>{getContent()}</Container>
    </SkeletonContainer>
  );
};

export default HelppageView;
