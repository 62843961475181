import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { color, DeviceType } from '../config';
import { flex, media, transition } from '../helper';
import LogoImg from '../../img/logo.svg';
import { Link, useHistory } from 'react-router-dom';
import { Sidenav } from '../../navigation/Sidenav';
import { Logo } from '../atoms';
import { SearchBar } from '../../search/SearchBar';
import { FlexRow } from '../modules/grid';
import { useI18n } from '../../i18n/I18nContext';

interface IPageHeaderProps {}

export const PageHeader: React.FC<IPageHeaderProps> = () => {
  const [navVisible, setNavVisible] = useState(false);
  const [searchBarVisible, setSearchBarVisible] = useState(true);
  const history = useHistory();
  const { t } = useI18n('ui.PageHeader');

  useEffect(() => {
    const listener = (location: any) => {
      if (location.pathname === '/') return setSearchBarVisible(false);
      else setSearchBarVisible(true);
    };
    const unlisten = history.listen(listener);
    listener(window.location);
    return unlisten;
  }, [history]);

  return (
    <HeaderWrapper>
      <HeaderLinks>
        <Link to="/">
          <Logo src={LogoImg} />
        </Link>

        {searchBarVisible && <HeaderSearchBar />}

        <NavToggle onClick={() => setNavVisible(!navVisible)}>
          <NavIcon className="fa fa-bars" />
          {t('btn_menu')}
        </NavToggle>
      </HeaderLinks>
      <Sidenav visible={navVisible} setVisible={setNavVisible} />
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.header`
  max-width: 1920px;
  margin: 0 auto;
  padding: 1rem 2rem; // fallback if min() is not supported
  padding: 1rem min(5vw, 2rem);
  background: ${color.text.light};
`;

const HeaderLinks = styled.div`
  ${flex('row', 'center', 'space-between')};
`;

const HeaderSearchBar = styled(SearchBar)`
  margin-left: 1rem;
  margin-right: 1rem;

  ${media(DeviceType.Laptop)} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const NavToggle = styled(FlexRow)`
  display: inline-flex;
  // margin & padding to create a bigger hitbox without changing the layout dimensions
  margin: -0.5rem;
  padding: 0.5rem;

  font-size: 0.9rem;
  font-weight: 700;

  border-radius: 4px;
  cursor: pointer;

  ${transition('.1s', 'color', 'background-color')};

  &:hover {
    color: ${color.brand.primary};
    background: ${color.grey.bg};
  }
`;

export const NavIcon = styled.i`
  margin-right: 0.75rem;
  font-size: 1.2rem;
`;
