import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { CategoryDetailView } from './category/CategoryDetailView';
import ErrorView from './error-pages/ErrorView';
import HelppageView from './helppage/HelppageView';
import LandingpageView from './landingpage/LandingpageView';
import PageLayout from './ui/components/PageLayout';
import { GlobalStyle } from './ui/global';
import { ScrollToTop } from './routing/ScrollTop';
import { SearchResultsView } from './search/SearchResultsView';
import { SearchContextProvider } from './search/SearchContext';
import { UIKitView } from './ui-kit/UIKitView';
import { RatingsAdminView } from './ratings/RatingsAdminView';

import { fetchLang } from './i18n/I18nResource';
import { I18nContextProvider } from './i18n/I18nContext';
import { ConsentManagerScript } from './consent-manager/ConsentManagerScript';
fetchLang('en');

const App: React.FunctionComponent = () => (
  <BrowserRouter>
    <I18nContextProvider>
      <SearchContextProvider>
        <GlobalStyle />
        <ScrollToTop />
        <PageLayout>
          <Switch>
            <Route exact path="/helppage/:helppageId" component={HelppageView} />
            <Route exact path="/category/:categoryId" component={CategoryDetailView} />
            <Route exact path="/search/:query" component={SearchResultsView} />
            <Route exact path="/error/:status" component={ErrorView} />
            <Route exact path="/ratings" component={RatingsAdminView} />
            <Route exact path="/uikit" component={UIKitView} />
            <Route exact path="/" component={LandingpageView} />
            <Redirect to="/error/404" />
          </Switch>
        </PageLayout>
      </SearchContextProvider>
    </I18nContextProvider>
    <ConsentManagerScript />
  </BrowserRouter>
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
