import { ILinkItem } from "../landingpage/landingpage.types";

export const HELPPAGE__TEXT = 'helppage__text';
export const HELPPAGE__IMAGE = 'helppage__image';

// TODO get lowercase names from api
export interface IHelppageDTO {
  Id: number;
  Breadcrumbs: IBreadcrumbDTO[];
  Title: string;
  TitleNav: string;
  Video: string;
  Content: (IImageComponent | ITextComponent)[];
  RelatedArticles: ILinkItem[]
}

export interface IBreadcrumbDTO {
  Id: number;
  Name: string;
  Type: string;
}

export interface IImageComponent {
  Type: string;
  Src: string;
  Description: string;
}

export interface ITextComponent {
  Type: string;
  Content: string;
}
