import { urlApi } from '../config';
import { Language } from '../i18n/i18n.types';
import { HelpcenterResource } from '../resource/HelpcenterResource';

/**
 * NOTE: Usually you don't want to call these methods to use feature flags.
 * Instead use the "useFeatureFlags" hook.
 */

/**
 * Fetch one feature flag from the api.
 * @param name feature flag name
 * @returns Promise resolving with an IFeature object fetched from api.
 */
const read = (name: string) =>
  HelpcenterResource.fetchJson(`${urlApi}/featureFlag/getFeature?name=${name}`, Language.None);

/**
 * 
 * @param names array of feature flag names to fetch
 * @returns Promise resolving with an array of IFeature objects fetched from api in the same order as the flag names were provided.
 */
const readMany = (names: string[]) =>
  HelpcenterResource.fetchJson(`${urlApi}/featureFlag/getFeatures?names=${JSON.stringify(names)}`, Language.None);

/**
 * Export one object that wraps the Resource methods.
 */
export const FeatureFlagResource = { read, readMany };
