import { CacheRead, CacheWrite, CacheStorage } from './resource.types';

export function CacheFactory() {
  let cache: CacheStorage = {};

  const read: CacheRead = (url, lang) => {
    // check if there is a cached value
    if (cache[url] && cache[url][lang]) {
      const cached = cache[url][lang];
      if (cached instanceof Promise) return cached as Promise<any>;
      if (cached) return new Promise(res => res(cached));
    }
    return null;
  };

  const write: CacheWrite = (url, lang, data) => {
    if (!cache[url]) cache[url] = {};
    cache[url][lang] = data;
  };

  return { read, write };
}
