import React, { HTMLProps } from 'react';
import styled from 'styled-components/macro';
import { useI18n } from '../i18n/I18nContext';
import { ILinkItem } from '../landingpage/landingpage.types';
import { ShowMoreLink } from '../ui/components/ShowMoreLink';
import { StyledLinkDecent } from '../ui/components/StyledLink';
import { color } from '../ui/config';

interface IHelppageLinkProps extends HTMLProps<HTMLDivElement> {
  as?: any;
  ref?: any;

  link: ILinkItem;
}

export const HelppageLink: React.FC<IHelppageLinkProps> = ({ link, ...props }) => {
  const { t } = useI18n('helppage.HelppageLink');

  const to = `/helppage/${link.Id}`;

  return (
    <Wrapper {...props}>
      <TextLink to={to}>{link.Name}</TextLink>

      <MoreLink to={`/helppage/${link.Id}`}>{t('btn_gotoArticle')}</MoreLink>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  line-height: 1.2em;

  & + & {
    margin-top: 1.5rem;
  }
`;

const TextLink = styled(StyledLinkDecent).attrs({ noUnderline: true })`
  padding-right: 0.2rem;
  color: ${color.text.primary};
`;

const MoreLink = styled(ShowMoreLink)`
  display: block;
  font-size: 0.9em;
`;
