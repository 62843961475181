import { urlApi } from '../config';
import { Language } from '../i18n/i18n.types';
import { HelpcenterResource } from '../resource/HelpcenterResource';
import { IHelppageDTO } from './helppage.types';

const { fetchJson } = HelpcenterResource;
const read = (id: number, lang: Language) => fetchJson(`${urlApi}/helppage/getHelppage/${id}`, lang) as Promise<IHelppageDTO>;
const byCategory = (id: number, lang: Language) =>
  fetchJson(`${urlApi}/helppage/GetHelppagesByCategory/${id}`, lang) as Promise<IHelppageDTO[]>;

export const HelppageResource = {
  read: read,
  byCategory: byCategory,
};
