import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { getFlagIconByLanguage } from '../i18n/flags';
import { useI18n } from '../i18n/I18nContext';
import { H3, Meta } from '../ui/atoms';
import { Modal, ModalButton, ModalButtons } from '../ui/components/Modal';
import { color } from '../ui/config';
import { Button } from '../ui/modules/controls/Button';
import { UiControlSize } from '../ui/modules/controls/controls.types';
import { FlexRow } from '../ui/modules/grid';
import { IArticleRatingGetDTO, ICommentDTO, II18nDtoContainer } from './ratings.types';

interface IRatingCommentsProps {
  i18nDtoContainer: II18nDtoContainer<IArticleRatingGetDTO>;
}

export const RatingComments: React.FC<IRatingCommentsProps> = ({ i18nDtoContainer }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [langFilter, setLangFilter] = useState('');
  const { t } = useI18n('ratings.RatingComments');

  return (
    <div>
      <Button secondary size={UiControlSize.Small} onClick={() => setModalOpen(true)} className="m-top">
        {t('btn_showComments', i18nDtoContainer.comments.length)}
      </Button>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <H3>{t('headline_comments', i18nDtoContainer.title)}</H3>

        {/* filter by language */}
        <FlexRow className="m-top">
          {t('filterBy')}
          {getLanguages(i18nDtoContainer).map(lang => (
            <Button key={lang} tertiary size={UiControlSize.Small} className="m-left_sm" onClick={() => setLangFilter(lang)}>
              {lang || t('filterBy_all')}
            </Button>
          ))}
        </FlexRow>

        {/* show sorted and filtered comments */}
        {i18nDtoContainer.comments
          .sort(commentSorter)
          .filter(comment => (langFilter ? comment.Language === langFilter : true))
          .map((feedback, i) => (
            <Comment key={i}>
              {/* timestamp and language */}
              <FlexRow>
                <Meta small>{feedback.CreatedAt?.toString().replace('T', ' ')}</Meta>
                {getFlagIconByLanguage(feedback.Language, { className: 'm-left_sm', small: true })}
              </FlexRow>

              {/* content / message */}
              <p>{feedback.Message}</p>
            </Comment>
          ))}

        <ModalButtons>
          <ModalButton secondary onClick={() => setModalOpen(false)}>
            {t('btn_closeModal')}
          </ModalButton>
        </ModalButtons>
      </Modal>
    </div>
  );
};

const getLanguages = (ratings: II18nDtoContainer<IArticleRatingGetDTO>) => ratings.byLang.map(dto => dto.Language + '');

const commentSorter = (a: ICommentDTO, b: ICommentDTO) => {
  if (!a.CreatedAt) return 1;
  if (!b.CreatedAt) return -1;
  if (a.CreatedAt > b.CreatedAt) return -1;
  if (a.CreatedAt < b.CreatedAt) return 1;
  return 0;
};

const Comment = styled.div`
  margin: 1rem 0;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${color.grey.border};
`;
