import React, { HTMLProps } from 'react';
import styled from 'styled-components/macro';
import { color } from '../config';

interface IArrowIconProps extends HTMLProps<SVGElement> {
  fill?: string;

  as?: any;
  crossOrigin?: any;
  ref?: any;
}

export const ArrowIcon: React.FC<IArrowIconProps> = ({ fill = color.text.primary, ...props }) => {
  return (
    <svg width="30" height="12" viewBox="0 0 30 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <PathAnimated
        d="M23.993 0.960754L22.937 2.04L26.1493 5.25H0.515381V6.75H26.1493L22.9378 9.96075L23.993 11.0393L29.0308 6L23.993 0.960754Z"
        fill={fill}
      />
    </svg>
  );
};

const PathAnimated = styled.path`
  transition: fill 0.15s;
`;
