import React, { useEffect } from 'react';
import { useFeatureFlags } from '../feature-flag/useFeatureFlags';

/**
 * Renders a script tag that loads the sfi-consent-manager if the feature flag 'consent-manager' is enabled
 */
export const ConsentManagerScript: React.FC = () => {
  const [consentManagerFeature] = useFeatureFlags('consent-manager');

  // render script only once
  useEffect(() => {
    if (!consentManagerFeature.Enabled) {
      return;
    }
    const script = document.createElement('script');
    script.id = 'usercentrics-cmp';
    script.src = 'https://app.usercentrics.eu/browser-ui/latest/loader.js';
    script.setAttribute('data-settings-id', '3cAowISnS');
    script.async = true;
    document.body.appendChild(script);
  }, [consentManagerFeature]);

  return null;
};
