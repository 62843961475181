import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { color } from '../../config';

import { flex, oneLine, transition } from '../../helper';
import { Link } from 'react-router-dom';
import { ArrowIcon } from '../../components/ArrowIcon';
import { IButtonProps, UiControlSize, IStyledButtonProps } from './controls.types';
import { getButtonArrowMargin, getButtonConfig } from './controls.config';
import { dontForward } from '../../../core/util';

export const Button: React.FC<IButtonProps> = ({
  icon,
  children,
  secondary,
  withArrow,
  withInput,
  size = UiControlSize.Default,
  to,
  type = 'button',
  ...props
}) => {
  const ButtonComponent = to ? StyledButtonLink : StyledButton;
  const [hover, setHover] = useState(false);

  return (
    // wrap with div to use inline-flex but still return a block element
    <div>
      <ButtonComponent
        secondary={secondary}
        withInput={withInput}
        size={size}
        type={type}
        to={to}
        iconOnly={icon && !children}
        {...props}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}>
        {icon && <ButtonIcon className={'fa ' + icon} withText={!!children} />}
        {children}
        {withArrow && <ButtonArrowIcon size={size} fill={!secondary || hover ? color.text.light : undefined} />}
      </ButtonComponent>
    </div>
  );
};

// ---- STYLED COMPONENTS ----

const StyledButton = styled('button').withConfig(
  dontForward('secondary', 'tertiary', 'size', 'hasIcon', 'iconOnly', 'withInput')
)<IStyledButtonProps>`
  ${oneLine};

  ${flex('row', 'center', 'center')};
  display: inline-flex; // use inline-flex as a-tag otherwise stretches over the whole screen
  flex: 0 0 auto;

  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.03em;
  text-decoration: none;
  text-decoration: none;

  cursor: pointer;
  border: none;

  color: ${color.text.light};
  background: ${color.brand.primary};

  ${transition('.15s', 'color', 'background-color', 'border-color')};

  ${props => {
    const c = getButtonConfig(props);
    return css`
      height: ${c.height};
      margin-left: ${props.withInput ? '-' + c.borderWidth : '0'}; // prevent double-border when next to textbox
      padding: ${c.padding};
      font-size: ${c.fontSize};
      border-width: ${c.borderWidth};
      border-radius: ${c.borderRadius};
    `;
  }};

  &:hover {
    background: ${color.brand.primary_dark};
  }

  ${props =>
    props.secondary &&
    css`
      color: inherit;
      background: transparent;
      // border width is size-specific
      border-style: solid;
      border-color: ${color.text.primary};

      &:hover {
        border-color: ${color.brand.primary};
        color: ${color.text.light};
        background: ${color.brand.primary};
      }
    `};

  ${props =>
    props.tertiary &&
    css`
      color: ${color.text.secondary};
      background: transparent;

      &:hover {
        background: ${color.grey.bg};
      }

      &:active {
        transition: none;
        background: ${color.grey.bg_hover};
      }
    `};
`;

const StyledButtonLink = styled(StyledButton).attrs({ as: Link })``;

export const ButtonIcon = styled.i<{ withText?: boolean }>`
  display: block;
  ${props => props.withText && 'margin-right: 0.7em'};
  font-size: 1.2em;
`;

const ButtonArrowIcon = styled(ArrowIcon).withConfig(dontForward('small'))<{ size: UiControlSize; fill?: string }>`
  display: block;
  margin-left: ${props => getButtonArrowMargin(props.size)};
  transform: translateX(0);
  transition: transform 0.3s;

  ${StyledButton}:hover & {
    transform: translateX(0.5em);
  }
` as any; // make it an any type as styled-components produces a weird bug otherwise
