import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useErrorRedirect from '../error-pages/useErrorRedirect';
import { NavigationResource } from '../navigation/NavigationResource';
import { Container, Section } from '../ui/modules/grid';
import { EmptyState, H1, Meta } from '../ui/atoms';
import { INavItem } from '../navigation/navigation.types';
import { HelppageResource } from '../helppage/HelppageResource';
import { IHelppageDTO } from '../helppage/helppage.types';
import { HelppageListItem } from '../helppage/HelppageListItem';
import SkeletonContainer from '../skeleton/SkeletonContainer';
import { CategorySkeleton } from './CategorySkeleton';
import styled from 'styled-components/macro';
import { color } from '../ui/config';
import { setDocumentTitle } from '../core/util';
import { useI18n } from '../i18n/I18nContext';
import { BackButton } from '../ui/components/BackButton';

export const CategoryDetailView = () => {
  const { categoryId } = useParams() as any;
  const [category, setCategory] = useState<INavItem>();
  const [helppages, setHelppages] = useState<IHelppageDTO[]>();
  const { t, lang } = useI18n('category.CategoryDetailView');
  const redirectToErrorPage = useErrorRedirect();

  useEffect(() => {
    NavigationResource.read(lang).then(data => {
      const cat = data.find(c => c.Id.toString() === categoryId);
      if (!cat) return redirectToErrorPage(404);
      setCategory(cat);
      setDocumentTitle(cat.Name);
    });
  }, [categoryId, lang, redirectToErrorPage]);

  useEffect(() => {
    HelppageResource.byCategory(categoryId, lang)
      .then(pages => {
        if (pages === null) return redirectToErrorPage(404);
        setHelppages(pages);
      })
      .catch(() => redirectToErrorPage(503));
  }, [categoryId, lang, redirectToErrorPage]);

  const getContent = useCallback(() => {
    if (!helppages || !category) return null;
    if (!helppages.length) return <EmptyState>{t('noHelppagesInCategory')}</EmptyState>;
    return (
      <div>
        {helppages.map(page => (
          <HelppageListItem key={page.Id} helppage={page} />
        ))}
      </div>
    );
  }, [category, helppages, t]);

  return (
    <SkeletonContainer skeleton={<CategorySkeleton />} showContent={!!category && !!helppages}>
      <Container narrow>
        <Section first>
          <CategoryHeader>
            <Meta small>
              <BackButton to="/">{t('btn_backToLanding')}</BackButton>
            </Meta>
            <H1>{t('headline_page', category?.Name || '')}</H1>
            <Meta small className="m-top_sm">
              {category?.Description}
            </Meta>
          </CategoryHeader>
          {getContent()}
        </Section>
      </Container>
    </SkeletonContainer>
  );
};

const CategoryHeader = styled.div`
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid ${color.grey.border};
`;
