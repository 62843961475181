import React from 'react';
import styled from 'styled-components';
import { color } from '../config';
import { transition } from '../helper';
import { StyledLinkDecent } from './StyledLink';

interface IBackButtonProps {
  to: string;
}

export const BackButton: React.FC<IBackButtonProps> = ({ to, children }) => {
  return (
    <StyledBackButtonLink to={to}>
      <Icon className="fa fa-angle-left" />
      {children}
    </StyledBackButtonLink>
  );
};

const Icon = styled.i`
  margin-right: 0.5rem;
  transform: translateX(0);
  ${transition('.2s', 'color', 'transform')};
`;

const StyledBackButtonLink = styled(StyledLinkDecent)`
  &:hover {
    /* text-decoration: none; */

    ${Icon} {
      color: ${color.brand.primary};
      transform: translateX(-0.3em);
    }
  }
`;
