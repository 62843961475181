import React, { useState } from 'react';
import { Button } from '../ui/modules/controls/Button';
import { UiControlSize } from '../ui/modules/controls/controls.types';
import { Textbox } from '../ui/modules/controls/Textbox';
import { FlexRow } from '../ui/modules/grid';
import { VariantTable } from './VariantTable';

export const UiInputButtonCombo = () => {
  const [textboxLabel, setTextboxLabel] = useState('Suchbegriff eingeben');
  const [buttonLabel, setButtonLabel] = useState('Suchen');

  const base = { textboxLabel };
  const group = [{ buttonLabel }, { icon: 'fa-search' }];
  const row = [{ size: UiControlSize.Small }, { size: UiControlSize.Default }, { size: UiControlSize.Large }];
  const column = [{ secondary: false }, { secondary: true }];
  const variantTableProps = { base, group, row, column, el: InputButtonCombo };

  return (
    <div>
      <FlexRow spaced>
        <Textbox
          label="Textbox label"
          value={textboxLabel}
          onChange={(ev: any) => setTextboxLabel(ev.target.value)}
          className="m-right"
        />
        <Textbox label="Button label" value={buttonLabel} onChange={(ev: any) => setButtonLabel(ev.target.value)} />
      </FlexRow>
      <VariantTable {...variantTableProps} />
    </div>
  );
};

interface IInputButtonComboProps {
  size: UiControlSize;
  secondary?: boolean;
  textboxLabel: string;
  buttonLabel: string;
  icon?: string;
}
const InputButtonCombo: React.FC<IInputButtonComboProps> = ({ size, secondary, textboxLabel, buttonLabel, icon }) => {
  return (
    <FlexRow>
      <Textbox label={textboxLabel} size={size} withButton />
      <Button secondary={secondary} size={size} icon={icon} withInput>
        {buttonLabel}
      </Button>
    </FlexRow>
  );
};
