import React from 'react';
import styled from 'styled-components/macro';
import { IInputsProps, UiControlSize } from './controls.types';
import { UiControlWrapper, StyledInput, UiLabel } from './Textbox';

interface ITextareaProps extends IInputsProps {
  rows?: number;
}

export const Textarea: React.FC<ITextareaProps> = ({
  className,
  size = UiControlSize.Default,
  animated = true,
  label,
  ...props
}) => {
  return (
    <UiControlWrapper className={className}>
      <StyledTextbox size={size} animated={animated} placeholder={label?.toString()} {...props} />
      {label && animated && <UiLabel size={size}>{label}</UiLabel>}
    </UiControlWrapper>
  );
};

const StyledTextbox = styled(StyledInput).attrs({ as: 'textarea' })`
  height: 9rem;
  line-height: 1.25;
  resize: vertical;
`;
