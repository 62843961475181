import styled, { css } from 'styled-components/macro';
import { color } from './config';

export const H1 = styled.h1<{ spaced?: boolean }>`
  font-weight: 300;
  font-size: 2.2rem;
  line-height: 1.2em;
  font-family: inherit;
  text-decoration: none;
  ${props => props.spaced && 'margin: 2rem 0'};
`;

export const H2 = styled.h2<{ spaced?: boolean }>`
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  text-decoration: none;
  letter-spacing: 0.01em;

  ${props => props.spaced && 'margin-bottom: 1.5rem'};
`;

export const H3 = styled.h3`
  font-weight: 700;
  font-size: 1.2rem;
  text-decoration: none;
  letter-spacing: 0.01em;
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 1rem;
  text-decoration: none;
  letter-spacing: 0.01em;
`;

export const P = styled.p`
  margin-bottom: 1.5rem;
`;

export const Strong = styled.strong`
  display: inline-block;
  font-weight: 700;
`;

export const Secondary = styled.span<{ small?: boolean }>`
  display: inline-block;
  color: ${color.text.secondary};
  ${props =>
    props.small &&
    css`
      font-weight: 400;
      font-size: 0.8em;
      line-height: 1.4em;
    `};
`;

export const Meta = styled(Secondary)`
  color: ${color.text.meta};
`;

export const EmptyState = styled.div`
  font-style: italic;
  font-size: 0.9rem;
  color: ${color.text.secondary};
`;

export const Logo = styled('img')`
  display: block;
  width: 30vw;
  max-width: 160px;
`;
