import React, { useMemo } from 'react';
import { parseCmsHtml } from '../../core/util';
import styled from 'styled-components/macro';

interface ITextComponentProps {
  content: string;
}

// const removeScriptTags = (html: string) => html.replace(/<\/*script(\s[\S]*)?>/g, '__unallowed-script__');

const TextComponent: React.FC<ITextComponentProps> = ({ content }) => {
  const parsed = useMemo(() => parseCmsHtml(content), [content]);
  return <StyledTextComponent>{parsed}</StyledTextComponent>;
};

const StyledTextComponent = styled.div`
  margin-bottom: 1.5rem;

  & p {
    margin-bottom: 1.5rem;
  }

  & strong {
    font-weight: 700;
  }
`;

export default TextComponent;
