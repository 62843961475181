import styled, { css } from 'styled-components/macro';
import { DeviceType, gridSpacingH, gridSpacingV } from '../config';
import { HTMLProps } from 'react';
import { media } from '../helper';

interface IFlexRowProps extends HTMLProps<HTMLDivElement> {
  direction?: string;
  align?: string;
  justify?: string;
  flexWrap?: boolean;
  explicitCols?: boolean;
  spaced?: boolean;
}

/**
 * Responsive flex row.
 * @param props.direction mimics CSS prop 'flex-direction'
 * @param props.align mimics CSS prop 'align-items'
 * @param props.justify mimics CSS prop 'justify-content'
 * @param props.flexWrap mimics CSS prop 'flex-wrap'
 * @param props.explicitCols if false you can just throw elements in the row but they wont have any spacing, otherwise use FlexCols as direct children
 * @param props.spaced if true add top and bottom margin
 */
export const FlexRow = styled.div<IFlexRowProps>`
  position: relative;
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : 'row')};
  align-items: ${props => (props.align ? props.align : props.explicitCols ? 'flex-start' : 'center')};
  justify-content: ${props => (props.justify ? props.justify : 'flex-start')};
  flex-wrap: ${props => (props.flexWrap ? 'wrap' : 'nowrap')};
  ${props =>
    !props.explicitCols &&
    props.spaced &&
    css`
      margin: 1rem 0;
    `}
  ${props =>
    props.explicitCols &&
    css`
      max-width: calc(100% + ${gridSpacingH} + ${gridSpacingH});
      margin-left: -${gridSpacingH};
      margin-right: -${gridSpacingH};
      margin-top: ${!props.spaced && '-'}${gridSpacingV};
      margin-bottom: ${!props.spaced && '-'}${gridSpacingV};
    `};
`;

interface IFlexRowResponsiveProps extends IFlexRowProps {
  breakAt?: DeviceType;
}

/**
 * Responsive flex row. For all params see FlexRow
 * @param props.breakAt DeviceType breakpoint where row changes direction from column to row
 */
export const FlexRowResponsive = styled(FlexRow)<IFlexRowResponsiveProps>`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${props => media(props.breakAt || DeviceType.Mobile)} {
    flex-direction: ${props => (props.direction ? props.direction : 'row')};
    align-items: ${props => (props.align ? props.align : 'center')};
    justify-content: ${props => (props.justify ? props.justify : 'flex-start')};
  }
`;

/**
 * Creates style rules for a set of responsive widths
 * @param widths - object width device widths mapped to element widths
 * @example getMediaFromObject({'400px': '100%', '700px': '50%}) ->
 *            @media (min-width: 400px) { flex: 0 0 50%; }
 *            @media (min-width: 700px) { flex: 0 0 33%; }
 */
const getMediaFromObject = (widths: any) =>
  Object.keys(widths)
    .map(device => `${media(device as DeviceType)} { flex: 0 0 ${widths[device]}; max-width: ${widths[device]}; }`)
    .join(' ');

interface IFlexColProps {
  width?: string;
  pushRight?: boolean;
  responsiveWidth?: any;
}

/**
 * Column used in FlexRow
 * @param width column width (width unit). Column may be bigger but not smaller than given value
 * @param pushRight if true pushes column to the right
 */
export const FlexCol = styled.div<IFlexColProps>`
  flex: 1 1 0;
  max-width: 100%;
  padding: ${gridSpacingV} ${gridSpacingH};
  ${props =>
    props.width &&
    css`
      flex: 0 0 ${props.width};
      max-width: ${props.width};
    `}
  ${props =>
    props.pushRight &&
    css`
      margin-left: auto;
    `}
  ${props => props.responsiveWidth && getMediaFromObject(props.responsiveWidth)};
`;

export const FlexColAdapting = styled(FlexCol).attrs({
  width: '100%',
  responsiveWidth: {
    [DeviceType.Mobile]: '50%',
    [DeviceType.Tablet]: '33%',
    [DeviceType.Laptop]: '25%',
  },
})``;

const sectionSpacingFirst = '3rem';
const sectionSpacing = '6rem';
export const Section = styled.div<{ first?: boolean; spacedH?: boolean }>`
  margin-top: ${props => (props.first ? sectionSpacingFirst : sectionSpacing)};
  margin-bottom: ${sectionSpacing};

  ${props =>
    props.spacedH &&
    css`
      margin-left: ${sectionSpacing};
      margin-right: ${sectionSpacing};
    `};
`;

export const SubSection = styled.div<{ first?: boolean }>`
  ${props =>
    !props.first &&
    css`
      margin-top: 2rem;
    `};
  margin-bottom: 2rem;
`;

export const Container = styled.main<{ narrow?: boolean }>`
  width: 90%;
  max-width: ${props => (props.narrow ? '850px' : '1200px')};
  margin: auto;
`;

export const FormRow = styled.div`
  margin-bottom: 1rem;
`;
