import React from 'react';
import { Skeleton, SkeletonH3, SkeletonLi } from '../skeleton/skeleton-styles';
import { SubSection } from '../ui/modules/grid';

const NavSkeleton: React.FC = () => (
  <Skeleton>
    <SubSection first>
      <SkeletonH3 />
      <SkeletonLi />
      <SkeletonLi />
      <SkeletonLi />
      <SkeletonLi />
    </SubSection>
    <SubSection>
      <SkeletonH3 />
      <SkeletonLi />
      <SkeletonLi />
      <SkeletonLi />
      <SkeletonLi />
      <SkeletonLi />
    </SubSection>
  </Skeleton>
);

export default NavSkeleton;
