import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { BasicState, useStateMachine } from '../core/useStateMachine';
import { H2 } from '../ui/atoms';
import { StatefulButton } from '../ui/components/StatefulButton';
import { color, DeviceType } from '../ui/config';
import { transition } from '../ui/helper';
import { Radio } from '../ui/modules/controls/Radio';
import { RadioGroup } from '../ui/modules/controls/RadioGroup';
import { Textarea } from '../ui/modules/controls/Textarea';
import { FlexCol, FlexRow, Section } from '../ui/modules/grid';
import { IHelppageDTO } from '../helppage/helppage.types';
import { RatingResource } from './RatingResource';
import { useI18n } from '../i18n/I18nContext';
interface IHelppageFeedbackSectionProps {
  helppage: IHelppageDTO;
}

export const RatingsSection: React.FC<IHelppageFeedbackSectionProps> = ({ helppage }) => {
  const [helpfulYN, setHelpfulYN] = useState<boolean>();
  const [feedbackText, setFeedbackText] = useState('');
  const { t, lang } = useI18n('ratings.RatingsSection');
  const { state, nextState } = useStateMachine(BasicState.Initial);

  const handleChangeYN = (val: string) => {
    const wasHelpful = val === 'true';
    setHelpfulYN(wasHelpful);
    RatingResource.writeRating(lang, { ArticleId: helppage?.Id, Language: lang, WasHelpful: wasHelpful });
    if (wasHelpful) nextState(BasicState.Success);
  };

  const handleSubmit = (ev: any) => {
    ev.preventDefault();
    const textTrimmed = feedbackText.trim();
    // send feedback if user gave some
    if (textTrimmed.length) {
      nextState(BasicState.Loading);
      RatingResource.writeFeedback(lang, { ArticleId: helppage?.Id, Language: lang, Message: textTrimmed })
        .then(() => nextState(BasicState.Success))
        .catch(() => nextState(BasicState.Error));
    } else nextState(BasicState.Success);
  };

  return (
    <Section>
      <FlexRow explicitCols>
        <FlexCol width="100%" responsiveWidth={{ [DeviceType.Tablet]: '75%' }}>
          <H2>{t('headline_wasHelpful')}</H2>
          <form onSubmit={handleSubmit}>
            <RadioGroup horizontal name="helpful-yn" onValueChange={handleChangeYN} className="m-top m-bottom">
              <Radio label={t('label_wasHelpfulYes')} value="true" />
              <Radio label={t('label_wasHelpfulNo')} value="false" className="m-left_xl" />
            </RadioGroup>

            {helpfulYN === false && (
              <div>
                <Textarea
                  label={t('label_feedback')}
                  value={feedbackText}
                  onChange={(ev: any) => setFeedbackText(ev.target.value)}
                />

                <StatefulButton
                  state={state}
                  labels={{ [BasicState.Loading]: 'Lade', [BasicState.Success]: 'Erledigt' }}
                  type="submit"
                  className="m-top">
                  {t('btn_submitFeedback')}
                </StatefulButton>
              </div>
            )}
          </form>

          <FeedbackSent visible={state === BasicState.Success}>
            <ShowRowWise visible={state === BasicState.Success}>
              <H2 className="m-bottom_sm">{t('headline_thankYou')}</H2>
              <p>{t('p_thankYou')}</p>
            </ShowRowWise>
          </FeedbackSent>
        </FlexCol>
      </FlexRow>
    </Section>
  );
};

export const ShowRowWise = styled.div<{ visible?: boolean }>`
  & > * {
    opacity: 0;
    visibility: hidden;
    transform: translateY(1rem);
    ${transition('.25s', 'opacity', 'visibility', 'transform')};

    ${props =>
      props.visible &&
      css`
        opacity: 1;
        visibility: visible;
        transform: translateY(0);

        // add transition delay when showing
        ${() => {
          let cssGen = '';
          for (let i = 1; i < 11; i++) {
            cssGen += `&:nth-child(${i}) { transition-delay: ${i * 0.05}s }`;
          }
          return cssGen;
        }}
      `};
  }
`;

const FeedbackSent = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 1.25rem; // same as GridCol padding
  background: ${color.text.light};

  opacity: 0;
  visibility: hidden;
  transform: translateY(0.5em);
  ${transition('.2s', 'opacity', 'visibility', 'transform')};

  ${props =>
    props.visible &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    `};
`;
