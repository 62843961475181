import React from 'react';
import { Skeleton, SkeletonH2, SkeletonLine } from '../skeleton/skeleton-styles';
import { SubSection, FlexRow, FlexCol } from '../ui/modules/grid';

export const SearchResultsSkeleton = () => {
  return (
    <Skeleton>
      {[1, 2, 3, 4].map(n => (
        <SubSection key={n}>
          <SkeletonH2 />
          <FlexRow explicitCols>
            <FlexCol width="30%">
              <SkeletonLine small />
            </FlexCol>
          </FlexRow>
          <SkeletonLine />
          <SkeletonLine />
          <SkeletonLine />
          <SkeletonLine />
        </SubSection>
      ))}
    </Skeleton>
  );
};
