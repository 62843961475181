import parse, { attributesToProps, domToReact } from 'html-react-parser';
import React, { ReactText } from 'react';
import { StyledADecent, StyledLinkDecent } from '../ui/components/StyledLink';

export const shallowCopy = (val: any) => {
  try {
    return JSON.parse(JSON.stringify(val));
  } catch (e) {
    return val;
  }
};

export const setDocumentTitle = (title: string, noSuffix: boolean = false) => {
  if (!title) title = 'Schweitzer Hilfecenter';
  else if (!noSuffix) title += ' – Schweitzer Hilfecenter';
  window.document.title = title;
};

export const getRandomId = (prefix?: string) => {
  prefix = prefix ? (prefix += '-') : '';
  return prefix + Math.round(Math.random() * Math.pow(10, 8));
};

export const parseCmsHtml = (html: string) => {
  return parse(html, {
    replace: ({ type, name, attribs, children }) => {
      if (type !== 'tag') return null;
      if (name === 'a') {
        let linkComponent = StyledLinkDecent;
        let newAttrs = shallowCopy(attribs);

        // if it's a local link, remove origin for <Link> component
        newAttrs.href = newAttrs.href.replace(window.location.origin, '');

        // make it an <a> tag if href is a domain. Otherwise href is a path and we need to use a <Link>
        if (newAttrs.href.match(/(http(s*):\/\/|www.|mailto:)/)?.length > 0) {
          // external domain
          linkComponent = StyledADecent;
        } else {
          // not an external domain
          newAttrs.to = newAttrs.href;
          delete newAttrs.href;
        }

        // external / internal link
        /*if (newAttrs.target === '_blank') {
          newAttrs.external = true;
        }*/

        // make children safe
        const childrenSafe = children || [];
        return React.createElement(linkComponent, attributesToProps(newAttrs), domToReact(childrenSafe));
      }
    },
  });
};

/**
 * Used for styled components.
 * Example without fn: styled(ParentComponent).withConfig({shouldForwardProp: prop => !['small', 'asBlock'].includes(prop)})``
 * @example styled(ParentComponent).withConfig(dontForward('small', 'asBlock'))``
 * @param props list of CSS props
 * @returns config object for styled-component
 */
export const dontForward = (...props: string[]) => ({ shouldForwardProp: (prop: ReactText) => !props.includes(prop + '') });
