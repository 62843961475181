import React, { HTMLProps, useState } from 'react';
import { FlexRow } from '../grid';

interface IRadioContext {
  name: string;
  selectedValue: string;
  setSelectedValue: (val: string) => void;
}

export const RadioContext = React.createContext<IRadioContext>({ name: '', selectedValue: '', setSelectedValue: () => {} });

interface IRadioGroupProps extends HTMLProps<HTMLDivElement> {
  horizontal?: boolean;
  name: string;
  onValueChange: (val: string) => void;

  as?: any;
  ref?: any;
}

export const RadioGroup: React.FC<IRadioGroupProps> = ({ name, horizontal, children, onValueChange, ...props }) => {
  const [selectedValue, setSelectedValue] = useState('');
  const Wrapper = horizontal ? FlexRow : 'div';

  const handleSelectedValueChange = (value: string) => {
    setSelectedValue(value);
    if (onValueChange) onValueChange(value);
  };

  return (
    <Wrapper {...props}>
      <RadioContext.Provider value={{ name, selectedValue, setSelectedValue: handleSelectedValueChange }}>
        {children}
      </RadioContext.Provider>
    </Wrapper>
  );
};
