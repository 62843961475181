import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BasicState, useStateMachine } from '../core/useStateMachine';
import { setDocumentTitle } from '../core/util';
import { IHelppageDTO } from '../helppage/helppage.types';
import { HelppageListItem } from '../helppage/HelppageListItem';
import { useI18n } from '../i18n/I18nContext';
import SkeletonContainer from '../skeleton/SkeletonContainer';
import { EmptyState, H1, Meta } from '../ui/atoms';
import { BackButton } from '../ui/components/BackButton';
import { Container, Section, SubSection } from '../ui/modules/grid';
import { SearchContext } from './SearchContext';
import { SearchResource } from './SearchResource';
import { SearchResultsSkeleton } from './SearchResultsSkeleton';

export const SearchResultsView = () => {
  const { query } = useParams() as any;
  const { query: queryContext, setQuery } = useContext(SearchContext);
  const [results, setResults] = useState([] as IHelppageDTO[]);
  const { t, lang } = useI18n('search.SearchResultsView');
  const { state, nextState, msg } = useStateMachine(BasicState.Loading);
  const history = useHistory();

  useEffect(() => {
    setDocumentTitle(`"${query}"`);
    nextState(BasicState.Loading);
    SearchResource.read(query, lang)
      .then(results => {
        if (!results || !results.length) {
          setResults([]);
          return nextState(BasicState.Error, t('err_noResults'));
        }
        setResults(results);
        nextState(BasicState.Success);
      })
      .catch(err => {
        nextState(BasicState.Error, t('err_serviceError'));
      });
  }, [query, lang, nextState]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!queryContext && query !== queryContext) setQuery(query);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const unlisten = history.listen(({ pathname }) => {
      if (pathname.indexOf('helppage') > -1) setQuery('');
    });

    return unlisten;
  }, [history, setQuery]);

  const getContent = () => {
    switch (state) {
      case BasicState.Success:
        return results.map(r => <HelppageListItem key={r.Id} helppage={r} />);
      case BasicState.Error:
        return <EmptyState>{msg}</EmptyState>;
    }
  };

  return (
    <Container narrow>
      <Section>
        <Meta small>
          <BackButton to="/">{t('btn_backToLanding')}</BackButton>
        </Meta>
        <H1>{t('headline_page', query)}</H1>
        {state !== BasicState.Loading && (
          <Meta>
            {results.length} {t('resultCount', results.length)}
          </Meta>
        )}

        <SubSection>
          <SkeletonContainer skeleton={<SearchResultsSkeleton />} showContent={state !== BasicState.Loading}>
            {getContent()}
          </SkeletonContainer>
        </SubSection>
      </Section>
    </Container>
  );
};
