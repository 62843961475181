import { createGlobalStyle } from 'styled-components/macro';
import { color } from './config';

// spacing rules (,m-<side>_<size>)
const sides = ['top', 'left', 'bottom', 'right'];
const sizes = [
  ['xs', '.25rem'],
  ['sm', '.5rem'],
  ['', '1rem'],
  ['lg', '2rem'],
  ['xl', '3rem'],
  ['auto', 'auto'],
];
const spacingRules = sides
  .map(side => sizes.map(([name, val]) => `.m-${side}${name && '_' + name} { margin-${side}: ${val}; }`).join(' '))
  .join(' ');

export const GlobalStyle = createGlobalStyle`
  *, *:before, *:after {
    position: relative;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }
  
  html {
    overflow-x: hidden;
  }
  
  html, body {
    min-height: 100vh;

    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    font-family: 'Univers', sans-serif;
    text-decoration: none;

    color: ${color.text.primary};
    background: ${color.text.light};
  }

  // ---- spacing rules
  ${spacingRules}

  // ---- FONTS ----
  @font-face {
    font-family: "Univers";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('/fonts/UniversLTW04-45LightOblique.eot?#iefix');
    src: url("/fonts/UniversLTW04-45LightOblique.woff2") format("woff2");
  }

  @font-face {
    font-family: "Univers";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('/fonts/UniversLTW04-45Light.eot?#iefix');
    src: url("/fonts/UniversLTW04-45Light.woff2") format("woff2");
  }

  @font-face {
    font-family: "Univers";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/UniversLTW05-55Roman.eot?#iefix');
    src: url("/fonts/UniversLTW05-55Roman.woff2") format("woff2");
  }

  @font-face {
    font-family: "Univers";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/UniversLTW04-65Bold.eot?#iefix');
    src: url("/fonts/UniversLTW04-65Bold.woff2") format("woff2");
  }
`;
