import React from 'react';
import { H1, H2 } from '../ui/atoms';
import { Container, Section } from '../ui/modules/grid';
import { UiButtons } from './UiButtons';
import { UiInputs } from './UiInputs';
import { UiInputButtonCombo } from './UiInputButtonCombo';
import { UiTypography } from './UiTypography';
import { UiLinks } from './UiLinks';

export const UIKitView = () => {
  return (
    <Container>
      <Section first>
        <H1>UI Kit</H1>
        <p>Space to test the SFI-UI-Kit.</p>
      </Section>

      <Section>
        <H2>Links</H2>
        <UiLinks />
      </Section>

      <Section>
        <H2>Buttons</H2>
        <UiButtons />
      </Section>

      <Section>
        <H2>inputs</H2>
        <UiInputs />
      </Section>

      <Section>
        <H2>input + button</H2>
        <UiInputButtonCombo />
      </Section>

      <Section>
        <H2>Typography</H2>
        <UiTypography />
      </Section>
    </Container>
  );
};
