import React from 'react';
import styled from 'styled-components/macro';
import { HELPPAGE__TEXT, IHelppageDTO, ITextComponent } from './helppage.types';
import { H3, Secondary } from '../ui/atoms';
import { StyledLinkDecent } from '../ui/components/StyledLink';
import { color } from '../ui/config';
import BreadcrumbContainer from './BreadcrumbContainer';

interface IHelppageListItemProps {
  helppage: IHelppageDTO;
}

const getPreviewText = (page: IHelppageDTO, length: number = 300) => {
  if (!page || !page.Content || !page.Content.length) return '';
  const filtered = page.Content.filter(c => c.Type === HELPPAGE__TEXT) as ITextComponent[];
  const preview = filtered
    .map(c => c.Content)
    .join(' ')
    .replace(/(<([^>]+)>)/gi, '');
  if (preview.length > length) return preview.substr(0, 300) + '...';
  return preview;
};

export const HelppageListItem: React.FC<IHelppageListItemProps> = ({ helppage }) => {
  return (
    <StyledHelppageListItem>
      <H3>
        <StyledLinkDecent to={`/helppage/${helppage.Id}`}>
          {helppage.Title}
        </StyledLinkDecent>
      </H3>
      <BreadcrumbContainer breadcrumbs={helppage.Breadcrumbs} className="m-bottom_sm" />
      <Secondary>{getPreviewText(helppage)}</Secondary>
    </StyledHelppageListItem>
  );
};

const StyledHelppageListItem = styled.div`
  margin-top: 2rem;
  /* padding-bottom: 1.2rem; */
  /* border-bottom: 1px solid ${color.grey.border}; */
`;
