import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Container, FlexColAdapting, FlexRow } from '../modules/grid';
import { color } from '../config';
import { H4 } from '../atoms';
import { NavigationResource } from '../../navigation/NavigationResource';
import { LinkListItem, LinkListItemA } from './LinkList';
import { INavItem } from '../../navigation/navigation.types';
import { useI18n } from '../../i18n/I18nContext';
import { LanguageSwitcher } from '../../i18n/LanguageSwitcher';
import { useFeatureFlags } from '../../feature-flag/useFeatureFlags';

/**
 * Returns the page footer, filled with links and a dynamic collection of all helppages.
 * @returns Footer component
 */
export const Footer: React.FC = () => {
  const [navItems, setNavItems] = useState([] as INavItem[]);
  const { t, lang } = useI18n('ui.Footer');
  const [consentManagerFeature] = useFeatureFlags('consent-manager');

  // fetch nav-items (all available (help)pages) 
  useEffect(() => {
    NavigationResource.read(lang).then(setNavItems);
  }, [setNavItems, lang]);

  const showCookieSettings = (ev: any) => {
    ev.preventDefault();

    const windowAsAny = window as any;
    windowAsAny.UC_UI?.showSecondLayer();
  };

  return (
    <FooterWrapper>
      <Container>
        {/* logo */}
        <FlexRow explicitCols flexWrap align="flex-start">
          <FlexColAdapting>
            <H4>{t('headline_languageSwitcher')}</H4>
            <LanguageSwitcher />
          </FlexColAdapting>

          {/* categories */}
          <FlexColAdapting>
            <H4>{t('headline_categories')}</H4>
            <div>
              {navItems &&
                navItems.map(i => (
                  <LinkListItem noUnderline invert to={`/category/${i.Id}`} key={i.Id}>
                    {i.Name}
                  </LinkListItem>
                ))}
            </div>
          </FlexColAdapting>

          {/* about */}
          <FlexColAdapting>
            <H4>{t('headline_sfiProducts')}</H4>
            <div>
              <LinkListItemA noUnderline invert href="https://schweitzer-online.de">
                {t('sfiShop')}
              </LinkListItemA>
              <LinkListItemA noUnderline invert href="https://mediacenter.schweitzer-online.de">
                {t('sfiMediacenter')}
              </LinkListItemA>
              <LinkListItemA noUnderline invert href="https://zid.schweitzer-online.de">
                {t('sfiZid')}
              </LinkListItemA>
              <LinkListItemA noUnderline invert href="https://connect.schweitzer-online.de">
                {t('sfiConnect')}
              </LinkListItemA>
            </div>
          </FlexColAdapting>

          {/* legal stuff */}
          <FlexColAdapting>
            <H4>{t('headline_legal')}</H4>
            <div>
              <LinkListItemA noUnderline invert href="https://mediacenter.schweitzer-online.de/statics/AGB.aspx">
                {t('agb')}
              </LinkListItemA>
              <LinkListItemA noUnderline invert href="https://www.schweitzer-online.de/info/Impressum/">
                {t('imprint')}
              </LinkListItemA>
              <LinkListItemA noUnderline invert href="https://mediacenter.schweitzer-online.de/statics/TermsOfUse.aspx">
                {t('terms')}
              </LinkListItemA>
              <LinkListItemA noUnderline invert href="https://mediacenter.schweitzer-online.de/statics/PrivacyTerms.aspx">
                {t('privacy')}
              </LinkListItemA>
              {consentManagerFeature.Enabled && (
                <LinkListItemA noUnderline invert href="#" onClick={showCookieSettings}>
                  {t('privacySettings')}
                </LinkListItemA>
              )}
            </div>
          </FlexColAdapting>
        </FlexRow>
      </Container>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer`
  margin-top: 4rem;
  padding: 4rem 0;
  overflow: hidden;

  background: ${color.grey.bg};
`;
