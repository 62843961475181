import { urlApi } from '../config';
import { IHelppageDTO } from '../helppage/helppage.types';
import { Language } from '../i18n/i18n.types';
import { HelpcenterResource } from '../resource/HelpcenterResource';

const { fetchJson } = HelpcenterResource;
const read = (query: string, lang: Language) =>
  fetchJson(`${urlApi}/search/Query/?query=${query}`, lang) as Promise<IHelppageDTO[]>;

export const SearchResource = { read };
