import styled, { css } from 'styled-components/macro';
import { StyledADecent, StyledLinkDecent } from './StyledLink';

const linkListItemContent = css`
  margin-top: 0.25rem;
  font-size: 0.9rem;
`;

export const LinkListItem = styled(StyledLinkDecent).attrs({ asBlock: true })`${linkListItemContent}`;
export const LinkListItemA = styled(StyledADecent).attrs({ asBlock: true })`${linkListItemContent}`;
