import React from 'react';
import {
  Skeleton,
  SkeletonHero,
  SkeletonH2,
  SkeletonVideo,
  SkeletonLine,
  SkeletonCategoryCard,
} from '../skeleton/skeleton-styles';
import { DeviceType } from '../ui/config';
import { Container, FlexCol, FlexRow, FlexRowResponsive, Section } from '../ui/modules/grid';

const LandingpageSkeleton: React.FC = () => (
  <Skeleton>
    <SkeletonHero />
    <Container>
      <Section first>
        <FlexRowResponsive explicitCols align="start">
          <FlexCol width="50%">
            <SkeletonH2 />
            <SkeletonVideo />
          </FlexCol>
          <FlexCol width="50%">
            <SkeletonH2 />
            <SkeletonLine li />
            <SkeletonLine li />
            <SkeletonLine li />
            <SkeletonLine li />
          </FlexCol>
        </FlexRowResponsive>
      </Section>
      <Section>
        <FlexRow explicitCols>
          <FlexCol width="50%">
            <SkeletonH2 />
          </FlexCol>
        </FlexRow>

        <FlexRow explicitCols>
          <FlexCol width="100%" responsiveWidth={{ [DeviceType.Tablet]: '50%', [DeviceType.Laptop]: '25%' }}>
            <SkeletonCategoryCard />
          </FlexCol>
          <FlexCol width="100%" responsiveWidth={{ [DeviceType.Tablet]: '50%', [DeviceType.Laptop]: '25%' }}>
            <SkeletonCategoryCard />
          </FlexCol>
          <FlexCol width="100%" responsiveWidth={{ [DeviceType.Tablet]: '50%', [DeviceType.Laptop]: '25%' }}>
            <SkeletonCategoryCard />
          </FlexCol>
          <FlexCol width="100%" responsiveWidth={{ [DeviceType.Tablet]: '50%', [DeviceType.Laptop]: '25%' }}>
            <SkeletonCategoryCard />
          </FlexCol>
        </FlexRow>
      </Section>
    </Container>
  </Skeleton>
);

export default LandingpageSkeleton;
