import React from 'react';
import { useParams } from 'react-router-dom';
import { useI18n } from '../i18n/I18nContext';
import { H1, P } from '../ui/atoms';
import { Container, Section } from '../ui/modules/grid';

interface IErrorViewProps {}

const ErrorView: React.FC<IErrorViewProps> = () => {
  const { status } = useParams() as any;
  const { t } = useI18n('errorPages.ErrorView');

  return (
    <Container>
      <Section>
        <H1>
          {status} - {t('errorTitle', status)}
        </H1>
        <P>{t('errorMessage', status)}</P>
      </Section>
    </Container>
  );
};

export default ErrorView;
