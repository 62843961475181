import React, { HTMLProps, useContext } from 'react';
import styled from 'styled-components/macro';
import { getRandomId } from '../../../core/util';
import { color } from '../../config';
import { RadioContext } from './RadioGroup';

interface IRadioProps extends HTMLProps<HTMLInputElement> {
  label: string;
  value: string;

  as?: any;
}

export const Radio: React.FC<IRadioProps> = ({ className, value, label, id = getRandomId('radio'), onChange, ...props }) => {
  const { name, selectedValue, setSelectedValue } = useContext(RadioContext);
  const checked = value === selectedValue;

  const handleChange = (ev: any) => {
    setSelectedValue(value);
    if (onChange) onChange(ev);
  };

  return (
    <RadioLabel htmlFor={id} checked={checked} className={className}>
      <HiddenInput id={id} type="radio" name={name} onChange={handleChange} {...props} />
      {label}
    </RadioLabel>
  );
};

const RadioLabel = styled.label<{ checked?: boolean }>`
  display: block;
  padding-left: 1.75rem;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0.15rem;
    left: 0;
    width: 1.2rem;
    height: 1.2rem;
    line-height: 1.2rem;
    border-radius: 1000px;
  }

  &:before {
    border: 2px solid ${color.text.primary};
  }

  &:after {
    background: ${color.text.primary};
    transform: scale(${props => (props.checked ? 0.6 : 0)});
    transition: transform 0.1s;
  }
`;

const HiddenInput = styled.input`
  display: none;
`;
