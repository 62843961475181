import { GLOBAL_FALLBACK_LANG, TranslationLang } from './i18n.types';

export const fetchLang = (lang: string) => {
  let data = TEST_JSON[lang];
  if (!data) data = TEST_JSON[lang.split('-')[0]];
  if (!data) data = TEST_JSON[GLOBAL_FALLBACK_LANG];

  return data;
};

// Test Data
type LangContainer = {
  [langKey: string]: TranslationLang;
};

const TEST_JSON: LangContainer = {
  en: {
    category: {
      CategoryDetailView: {
        noHelppagesInCategory: 'There are no articles in this category',
        btn_backToLanding: 'Back to landing page',
        headline_page: (topic: string) => `Articles about "${topic}"`,
      },
      CategoryGridItem: {
        btn_showArticles: 'Show Articles',
      },
    },
    errorPages: {
      ErrorView: {
        errorTitle: (code: string) => {
          const titles: { [code: string]: string } = {
            '000': 'Unknown error',
            '404': 'Not found',
            '500': 'Internal Server Error',
            '503': 'Service temporarily unavailable',
          };
          return titles[code] || titles['000'];
        },
        errorMessage: (code: string) => {
          const messages: { [code: string]: string } = {
            '000': "Sorry, that didn't work.",
            '404': 'Wir konnten diese Seite leider nicht finden.',
            '500': "Sorry, that didn't work.",
            '503': "Sorry, we can't reach the server right now. Please try again later.",
          };
          return messages[code] || messages['000'];
        },
      },
    },
    helppage: {
      HelppageLink: {
        btn_gotoArticle: 'View article',
      },
      HelppageView: {
        headline_relatedArticles: 'Related articles',
        err_notAvailableInCurrentLang: 'Sorry, this article is not available in English. Please select another language.',
      },
    },
    landingpage: {
      LandingpageView: {
        pageTitle: 'Welcome to Schweitzer Helpcenter',
        headline_welcome: 'Welcome to Schweitzer Helpcenter.\nHow can we help you today?',
        placeholder_search: 'Try to search for "Database"',
        headline_intro: 'Introduction to Schweitzer Mediacenter (german)',
        headline_faq: 'Frequently asked questions',
        headline_topics: 'Search by topic',
      },
    },
    navigation: {
      Sidenav: {
        btn_close: 'Close',
        emptyState: 'No articles found',
      },
    },
    ratings: {
      RatingComments: {
        headline_comments: (title: string) => `Comments ${title && `for article "${title}"`}`,
        btn_showComments: (commentCount: number) => `Show ${commentCount} comment${commentCount !== 1 ? 's' : ''}`,
        filterBy: 'Filter by:',
        filterBy_all: 'All',
        btn_closeModal: 'Close',
      },
      RatingsAdminView: {
        headline_feedbackByHelppage: 'Feedback by helppage',
        headline_enterPassword: 'Please enter your Admin-Password:',
        headline_articleNotAvailableInCurrentLang: '[No english title available]',
        label_pw: 'Password',
        btn_submitPw: 'Submit',
        btn_cancel: 'Cancel',
        waitingForPassword: 'Waiting for you to enter your password.',
        loading: 'Loading...',
        err_canceled: 'No password entered. Please refresh the page.',
        err_wrongPassword: 'Wrong password.',
        err_serviceError: 'Error while fetching data.',
      },
      RatingsSection: {
        headline_wasHelpful: 'Was this article helpful?',
        label_wasHelpfulYes: 'Yes',
        label_wasHelpfulNo: 'No',
        label_feedback: 'Why not?',
        btn_submitFeedback: 'Submit feedback',
        headline_thankYou: 'Thank you!',
        p_thankYou:
          'Your feedback was successfully submitted, you can also <a href="mailto:support.mediacenter@schweitzer-online.de">Send us an E-Mail</a>.',
      },
    },
    search: {
      SearchBar: {
        defaultLabel: 'Search',
      },
      SearchResultsView: {
        btn_backToLanding: 'Back to landing page',
        headline_page: (query: string) => `Search results for "${query}"`,
        resultCount: (resultCount: number) => `Result${resultCount !== 1 ? 's' : ''}`,
        err_noResults: 'No articles found.',
        err_serviceError: 'Error while fetching articles. Please try again.',
      },
    },
    ui: {
      Footer: {
        headline_languageSwitcher: 'Change language',

        headline_categories: 'Topics',

        headline_sfiProducts: 'More from Schweitzer',
        sfiShop: 'Shop',
        sfiMediacenter: 'Mediacenter',
        sfiZid: 'Zeitschrifteninhaltsdienst',
        sfiConnect: 'Connect',

        headline_legal: 'Legal',
        agb: 'AGB',
        imprint: 'Imprint',
        terms: 'Terms',
        privacy: 'Privacy',
        privacySettings: 'Privacy Settings',
      },
      PageHeader: {
        btn_menu: 'Menu',
      },
    },
  },
  de: {
    category: {
      CategoryDetailView: {
        noHelppagesInCategory: 'Keine Hilfeseiten vorhanden.',
        btn_backToLanding: 'Zurück zur Startseite',
        headline_page: (topic: string) => `Hilfeartikel zum Thema "${topic}"`,
      },
      CategoryGridItem: {
        btn_showArticles: 'Artikel anzeigen',
      },
    },
    errorPages: {
      ErrorView: {
        errorTitle: (code: string) => {
          const titles: { [code: string]: string } = {
            '000': 'Unknown error',
            '404': 'Not found',
            '500': 'Internal Server Error',
            '503': 'Service temporarily unavailable',
          };
          return titles[code] || titles['000'];
        },
        errorMessage: (code: string) => {
          const messages: { [code: string]: string } = {
            '000': 'Sorry, da ist etwas schief gelaufen...',
            '404': 'Wir konnten diese Seite leider nicht finden.',
            '500': 'Sorry, da ist etwas schief gelaufen.',
            '503':
              'Sorry, wir können den Server gerade leider nicht erreichen. In ein paar Minuten sollte aber alles wieder funktionieren',
          };
          return messages[code] || messages['000'];
        },
      },
    },
    helppage: {
      HelppageLink: {
        btn_gotoArticle: 'Zum Artikel',
      },
      HelppageView: {
        headline_relatedArticles: 'Ähnliche Artikel',
        err_notAvailableInCurrentLang:
          'Dieser Artikel ist leider nicht auf Deutsch verfügbar. Bitte wählen Sie eine andere Sprache.',
      },
    },
    landingpage: {
      LandingpageView: {
        pageTitle: 'Willkommen im Schweitzer Hilfecenter',
        headline_welcome: 'Willkommen im Schweitzer Hilfecenter.\nWie können wir Ihnen heute helfen?',
        placeholder_search: 'Suchen Sie z.B. nach "Datenbanken"',
        headline_intro: 'Einführung ins Schweitzer Mediacenter',
        headline_faq: 'Häufige Fragen',
        headline_topics: 'Hilfecenter nach Themen',
      },
    },
    navigation: {
      Sidenav: {
        btn_close: 'Schließen',
        emptyState: 'Keine Artikel vorhanden',
      },
    },
    ratings: {
      RatingComments: {
        headline_comments: (title: string) => `Kommentare ${title && `zum Artikel "${title}"`}`,
        btn_showComments: (commentCount: number) => `${commentCount} Kommentar${commentCount !== 1 ? 'e' : ''} anzeigen`,
        filterBy: 'Filtern nach:',
        filterBy_all: 'Alle',
        btn_closeModal: 'Schließen',
      },
      RatingsAdminView: {
        headline_feedbackByHelppage: 'Feedback nach Hilfeseite',
        headline_enterPassword: 'Bitte geben Sie ihr Admin-Passwort ein:',
        headline_articleNotAvailableInCurrentLang: '[Kein Deutscher Titel verfügbar]',
        label_pw: 'Passwort',
        btn_submitPw: 'Absenden',
        btn_cancel: 'Abbrechen',
        waitingForPassword: 'Warte auf Passwort-Eingabe.',
        loading: 'Lade Einträge...',
        err_canceled: 'Die Passworteingabe wurde abgebrochen. Bitte laden Sie die Seite neu.',
        err_wrongPassword: 'Fehlerhaftes Passwort.',
        err_serviceError: 'Fehler beim Abrufen der Daten.',
      },
      RatingsSection: {
        headline_wasHelpful: 'War dieser Artikel hilfreich?',
        label_wasHelpfulYes: 'Ja',
        label_wasHelpfulNo: 'Nein',
        label_feedback: 'Warum war dieser Artikel nicht hilfreich?',
        btn_submitFeedback: 'Feedback absenden',
        headline_thankYou: 'Vielen Dank!',
        p_thankYou:
          'Ihr Feedback wurde abgesendet. Falls Sie weitere Anmerkungen haben, <a href="mailto:support.mediacenter@schweitzer-online.de">schreiben Sie uns eine E-Mail</a>.',
      },
    },
    search: {
      SearchBar: {
        defaultLabel: 'Suchen',
      },
      SearchResultsView: {
        btn_backToLanding: 'Zurück zur Startseite',
        headline_page: (query: string) => `Suchergebnisse für "${query}"`,
        resultCount: (resultCount: number) => `Ergebnis${resultCount !== 1 ? 'se' : ''}`,
        err_noResults: 'Keine Suchergebnisse gefunden.',
        err_serviceError: 'Fehler beim Abrufen der Suchergebnisse. Bitte versuchen Sie es noch einmal.',
      },
    },
    ui: {
      Footer: {
        headline_languageSwitcher: 'Sprache wählen',

        headline_categories: 'Themen',

        headline_sfiProducts: 'Mehr von Schweitzer',
        sfiShop: 'Shop',
        sfiMediacenter: 'Mediacenter',
        sfiZid: 'Zeitschrifteninhaltsdienst',
        sfiConnect: 'Connect',

        headline_legal: 'Rechtliches',
        agb: 'AGB',
        imprint: 'Impressum',
        terms: 'Nutzungsbedingungen',
        privacy: 'Datenschutz',
        privacySettings: 'Cookie-Einstellungen',
      },
      PageHeader: {
        btn_menu: 'Menü',
      },
    },
  },
};
