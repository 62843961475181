import React from 'react';
import { H1, H2, H3, P, Secondary, Meta, Strong, EmptyState, H4 } from '../ui/atoms';
import { Table, Tr, Td } from './VariantTable';

export const UiTypography = () => {
  return (
    <Table>
      <tbody>
        <Tr>
          <Td>
            <H1>Headline 1</H1>
          </Td>
        </Tr>
        <Tr>
          <Td>
            <H2>Headline 2</H2>
          </Td>
          <Td>
            <H2 spaced>Headline 2 spaced</H2>
          </Td>
        </Tr>
        <Tr>
          <Td>
            <H3>Headline 3</H3>
          </Td>
        </Tr>
        <Tr>
          <Td>
            <H4>Headline 4</H4>
          </Td>
        </Tr>
        <Tr>
          <Td>
            <P>Paragraph</P>
          </Td>
        </Tr>
        <Tr>
          <Td>
            <Secondary>Secondary</Secondary>
          </Td>
          <Td>
            <Secondary small>Secondary small</Secondary>
          </Td>
        </Tr>
        <Tr>
          <Td>
            <Meta>Meta</Meta>
          </Td>
          <Td>
            <Meta small>Meta small</Meta>
          </Td>
        </Tr>
        <Tr>
          <Td>
            <Strong>Strong</Strong>
          </Td>
        </Tr>
        <Tr>
          <Td>
            <EmptyState>Empty State</EmptyState>
          </Td>
        </Tr>
      </tbody>
    </Table>
  );
};
