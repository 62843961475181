export enum Language {
  None = '',
  English = 'en',
  German = 'de',
}

export const GLOBAL_FALLBACK_LANG = Language.German;

// TODO better type names
export type TranslationEntries = { [key: string]: string | ((...args: any) => string) };
export type TranslationLang = {
  [feature: string]: {
    [component: string]: TranslationEntries;
  };
};
export type FnTranslate = (key: string, ...args: any) => string;

export interface II18nContext {
  lang: Language;
  setLang: (code: Language) => void;
  translations: TranslationLang;
  // translationFactory: (prefix: string) => (key: string, ...args: any) => string;
}
