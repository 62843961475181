import React, { useState } from 'react';

interface ISearchContext {
  query: string;
  setQuery: (query: string) => void;
}
export const SearchContext = React.createContext({ query: '', setQuery: () => undefined } as ISearchContext);

export const SearchContextProvider: React.FC = ({ children }) => {
  const [query, setQuery] = useState('');

  return <SearchContext.Provider value={{ query, setQuery }}>{children}</SearchContext.Provider>;
};
