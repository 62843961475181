import React from 'react';
import styled from 'styled-components/macro';
import { SearchBar } from '../search/SearchBar';
import { H1 } from '../ui/atoms';
import { color, DeviceType } from '../ui/config';
import { flex, media } from '../ui/helper';
import { Container } from '../ui/modules/grid';
import { UiControlSize } from '../ui/modules/controls/controls.types';

interface IHeroProps {
  title: string;
  searchHint: string;
}

export const Hero: React.FC<IHeroProps> = ({ title, searchHint }) => {
  return (
    <StyledHero>
      <Container>
        <HeroContent>
          <HeroTitle>{title}</HeroTitle>
          <HeroSearchBar searchHint={searchHint} />
        </HeroContent>
      </Container>
    </StyledHero>
  );
};

const StyledHero = styled.div`
  margin-bottom: 3rem;
  /* background: ${color.brand.primary_light}; */
`;

const HeroContent = styled.div`
  ${flex('column', 'flex-start', 'center')};
  padding: 3rem 0;
  text-align: left;

  ${media(DeviceType.Laptop)} {
    padding: 6rem 0;
  }
`;

const HeroTitle = styled(H1)`
  margin-bottom: 3rem;
  white-space: pre-line;
  /* background: ${color.brand.primary_light}; */
`;

const HeroSearchBar = styled(SearchBar).attrs({ size: UiControlSize.Large })`
  width: 100%;
  max-width: 580px;
`;
