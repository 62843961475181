import React from 'react';
import styled from 'styled-components/macro';
import { IStyledLinkProps, StyledLinkDecent } from './StyledLink';

interface IShowMoreLinkProps extends IStyledLinkProps {
  as?: any;
  ref?: any;
}
export const ShowMoreLink: React.FC<IShowMoreLinkProps> = ({ children, ...props }) => {
  return (
    <StyledShowMoreLink {...props}>
      {children}
      <ShowMoreIcon className="fa fa-angle-right" />
    </StyledShowMoreLink>
  );
};

const ShowMoreIcon = styled.i`
  padding-left: 0.5rem;
  font-size: 0.9em;

  transform: translateX(0);
  transition: transform 0.2s;
`;

const StyledShowMoreLink = styled(StyledLinkDecent)<{ asBlock?: boolean }>`
  display: ${props => (props.asBlock ? 'block' : 'inline-block')};

  &:hover {
    text-decoration: underline;

    /* ${ShowMoreIcon} {
      transform: translateX(0.3em);
    } */
  }
`;
