import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { StyledLink, StyledLinkDecent } from '../ui/components/StyledLink';
import { color } from '../ui/config';
import { Textbox } from '../ui/modules/controls/Textbox';
import { FlexRow } from '../ui/modules/grid';
import { VariantTable } from './VariantTable';

export const UiLinks = () => {
  const [label, setLabel] = useState('Click me');

  const base = { children: label, to: '#' };
  const group = [{}];
  const row = [{}, { internal: true }, { external: true }, { download: true }];
  const column = [{}, { invert: true }];
  const variantTableProps = { base, group, row, column, el: StyledLink };

  return (
    <div>
      <FlexRow spaced>
        <Textbox label="Link text" value={label} onChange={(ev: any) => setLabel(ev.target.value)} />
      </FlexRow>
      <InvertedVariantTable {...variantTableProps} />
      <StyledLinkDecent to="#">{label}</StyledLinkDecent>
    </div>
  );
};

const InvertedVariantTable = styled(VariantTable)`
  & td:nth-child(even) {
    background: ${color.text.primary};
  }
`;
