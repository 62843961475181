import React, { ReactNode, useEffect, useState } from 'react';

interface ISkeletonContainerProps {
    showContent: boolean;
    skeleton: ReactNode;
    timeout?: number
}

/**
 * Conditionally shows a skeleton.
 * Shows nothing for <timeout>ms, then the skeleton-screen until <showContent> is true.
 * We're using the timeout here so the skeleton screen won't show on fast loading pages (prevent spinner-madness)
 * @param props
 */
const SkeletonContainer: React.FC<ISkeletonContainerProps> = ({ children, skeleton, timeout = 300, showContent }) => {
    const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
    const [timeoutStore, setTimeoutStore] = useState<any>(null);

    // set up a timeout initially; clear timeout once content is shown for page-transitions to the same component (e.g. /helppage/1070 -> /helppage/1071)
    useEffect(() => {
        if (showContent) {
            clearTimeout(timeoutStore);
            setTimeoutStore(null);
            setShowSkeleton(false);
        }
        else if (!timeoutStore) {
            setTimeoutStore(setTimeout(() => {
                setShowSkeleton(true);
            }, timeout));
        };
    }, [timeout, timeoutStore, showContent, setShowSkeleton]);

    const getContent = () => {
        if (showContent) return children;
        if (showSkeleton) return skeleton;
        return null
    }

    return <>{getContent()}</>
}

/* TODO animate skeleton to content transition. Here's some kinda working code
    const getContent = () => {
        let content = null;
        if (showContent) content = children;
        if (showSkeleton) content = skeleton;

        return <CSSTransition
            key={(showContent ? 10 : 0) + (showSkeleton ? 1 : 0)}
            classNames="skeleton-transition"
            timeout={200}>
            <div>{content}</div>
        </CSSTransition>;
    }

    return <TransitionGroup className="skeleton-transition-group">
        {getContent()}
    </TransitionGroup>
*/

export default SkeletonContainer;