import React, { HTMLProps, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Button } from '../ui/modules/controls/Button';
import { flex } from '../ui/helper';
import { Textbox } from '../ui/modules/controls/Textbox';
import { SearchContext } from './SearchContext';
import { UiControlSize } from '../ui/modules/controls/controls.types';
import { useI18n } from '../i18n/I18nContext';
import { getButtonConfig } from '../ui/modules/controls/controls.config';

interface ISearchBarProps extends HTMLProps<HTMLDivElement> {
  as?: any;
  size?: UiControlSize;
  searchHint?: string;
}

export const SearchBar: React.FC<ISearchBarProps> = ({ size = UiControlSize.Default, searchHint, ...props }) => {
  const { query, setQuery } = useContext(SearchContext);
  const { t } = useI18n('search.SearchBar');
  const history = useHistory();

  const handleSubmit = (ev: any) => {
    ev.preventDefault();
    if (!query || !query.trim()) return;
    history.push(`/search/${encodeURIComponent(query.trim())}`);
  };

  return (
    <SearchBarForm onSubmit={handleSubmit} {...props}>
      <SearchInput
        withButton
        size={size}
        value={query}
        onChange={(ev: any) => setQuery(ev.target.value)}
        label={searchHint || t('defaultLabel')}
      />
      <SearchIcon size={size} secondary={size !== UiControlSize.Large} />
    </SearchBarForm>
  );
};

const SearchBarForm = styled.form`
  ${flex('row', 'center', 'center')};
  flex: 0 1 100%;
  width: 100%;
  max-width: 400px;
`;

const SearchInput = styled(Textbox)`
  width: 100%;
`;

const SearchIcon = styled(Button).attrs({
  type: 'submit',
  withInput: true,
  icon: 'fa-search',
})<{ size: UiControlSize }>`
  /* move icon to the left depending on its border width */
  margin-left: -${({ size }) => getButtonConfig({ size, iconOnly: true, secondary: true }).borderWidth};
`;
