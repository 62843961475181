import { HTMLProps } from 'react';

export enum UiControlSize {
  Small,
  Default,
  Large,
}

// button
export interface IButtonProps extends HTMLProps<HTMLButtonElement> {
  size?: UiControlSize;
  secondary?: boolean;
  tertiary?: boolean;
  withArrow?: boolean;
  withInput?: boolean;
  icon?: string;
  to?: string;

  as?: any;
}

export interface IStyledButtonProps {
  secondary?: boolean;
  tertiary?: boolean;
  size: UiControlSize;
  iconOnly: boolean;
  withInput?: boolean;
}

export interface IButtonConfig {
  height: string;
  padding: string;
  fontSize: string;
  borderWidth: string;
  borderRadius: string;
}

// input
export interface IInputsProps extends HTMLProps<HTMLInputElement> {
  ref?: any;
  as?: any;
  label?: string;
  animated?: boolean;
  withButton?: boolean;
}

export interface IStyledInputProps {
  size: UiControlSize;
  animated?: boolean;
  withButton?: boolean;
}

export interface IInputConfig {
  height: string;
  padding: string;
  fontSize: string;
  lineHeight: string;
  borderWidth: string;
  borderRadius: string;
}
