import React from 'react';
import styled, { css } from 'styled-components/macro';
import { color } from '../../config';
import { oneLine } from '../../helper';
import { getInputConfig, getUiLabelConfig } from './controls.config';
import { IInputsProps, IStyledInputProps, UiControlSize } from './controls.types';

export const Textbox = React.forwardRef<HTMLInputElement, IInputsProps>(
  ({ label, className, size = UiControlSize.Default, animated = true, ...props }, ref) => {
    return (
      <UiControlWrapper className={className}>
        <StyledInput size={size} animated={animated} placeholder={label?.toString()} ref={ref} {...props} />
        {label && animated && <UiLabel size={size}>{label}</UiLabel>}
      </UiControlWrapper>
    );
  }
);

export const UiControlWrapper = styled.div`
  width: 100%;
`;

export const UiLabel = styled.label<{ size: UiControlSize }>`
  ${oneLine};
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;

  font-weight: 500;
  font-size: 0.9rem;
  text-decoration: none;
  text-align: left;

  color: ${color.text.meta};

  transform-origin: top left;
  transition: transform 0.15s;

  ${props => {
    const { width, transform } = getUiLabelConfig(props.size, false);
    return css`
      width: ${width};
      transform: ${transform};
    `;
  }}

  ${props =>
    props.size === UiControlSize.Large &&
    css`
      font-size: 1.1rem;
    `};
`;

export const StyledInput = styled.input<IStyledInputProps>`
  appearance: none;

  display: block;
  width: 100%;

  font: inherit;
  font-weight: 400;
  font-size: 0.9rem;
  text-decoration: none;
  text-align: left;

  // border width is defined for different sizes below
  border-style: solid;
  border-color: ${color.text.primary};
  color: inherit;
  background: none;
  box-shadow: none;

  ${props =>
    props.animated &&
    css`
      &::placeholder {
        color: transparent;
      }

      &:focus,
      &:not(:placeholder-shown) {
        ~ ${UiLabel} {
          transform: ${getUiLabelConfig(props.size, true).transform};
        }
      }
    `};

  ${props => {
    const c = getInputConfig(props);
    return css`
      height: ${c.height};
      padding: ${c.padding};
      line-height: ${c.lineHeight};
      font-size: ${c.fontSize};
      border-width: ${c.borderWidth};
      border-radius: ${props.withButton ? `${c.borderRadius} 0 0 ${c.borderRadius}` : c.borderRadius};
    `;
  }};
`;
