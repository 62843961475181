import { UiControlSize, IStyledButtonProps, IStyledInputProps, IInputConfig, IButtonConfig } from './controls.types';

// ---- UI-CONTROL SETTINGS ----
const uiControlProps = {
  [UiControlSize.Small]: {
    height: 40,
    borderWidth: 2,
    borderRadius: 4,
  },
  [UiControlSize.Default]: {
    height: 52,
    borderWidth: 2,
    borderRadius: 6,
  },
  [UiControlSize.Large]: {
    height: 68,
    borderWidth: 3,
    borderRadius: 8,
  },
};

// ---- BUTTON SETTINGS ----
const buttonProps = {
  [UiControlSize.Small]: {
    ...uiControlProps[UiControlSize.Small],
    paddingH: 21,
    arrowMargin: 14,
    fontSize: 0.7,
  },
  [UiControlSize.Default]: {
    ...uiControlProps[UiControlSize.Default],
    paddingH: 30,
    arrowMargin: 16,
    fontSize: 0.7,
  },
  [UiControlSize.Large]: {
    ...uiControlProps[UiControlSize.Large],
    paddingH: 37,
    arrowMargin: 16,
    fontSize: 0.9,
  },
  iconOnlyFactor: 0.7, // paddingH gets multiplied by this factor if it's an icon-only button
};

// ---- INPUT SETTINGS ----
const inputProps = {
  [UiControlSize.Small]: {
    ...uiControlProps[UiControlSize.Small],
    labelTransform: [5, 0], // label transform [default, active]
    padding: [15, 14, 3], // padding [top, horizontal, bottom]
    fontSize: 0.9,
  },
  [UiControlSize.Default]: {
    ...uiControlProps[UiControlSize.Default],
    labelTransform: [10, 4],
    padding: [23, 14, 7],
    fontSize: 0.9,
  },
  [UiControlSize.Large]: {
    ...uiControlProps[UiControlSize.Large],
    labelTransform: [18, 9],
    padding: [29, 14, 11],
    fontSize: 1.1,
  },
};

// helper functions
// button
export const getButtonArrowMargin = (size: UiControlSize) => `${buttonProps[size].arrowMargin || 0}px`;

const getButtonPadding = (size: UiControlSize, iconOnly: boolean, secondary: boolean, withInput: boolean) => {
  let paddingH = buttonProps[size].paddingH || 0;
  if (iconOnly) paddingH *= buttonProps.iconOnlyFactor;
  if(withInput) paddingH *= 1.4;
  if (secondary) paddingH -= 2; // subtract 2 for secondary button border
  return `0 ${paddingH}px`;
};

export const getButtonConfig = ({ size, iconOnly, secondary, withInput }: IStyledButtonProps) => {
  const props = buttonProps[size] || buttonProps[UiControlSize.Default];
  return {
    height: props.height + 'px',
    padding: getButtonPadding(size, iconOnly, !!secondary, !!withInput),
    fontSize: props.fontSize + 'rem',
    borderWidth: props.borderWidth + 'px',
    borderRadius: withInput ? `0 ${props.borderRadius}px ${props.borderRadius}px 0` : props.borderRadius + 'px',
  } as IButtonConfig;
};

// input
const getInputPadding = (size: UiControlSize, animated: boolean) => {
  const { padding } = inputProps[size] || inputProps[UiControlSize.Default];
  if (!animated) return `0 ${padding[1]}px`;
  return `${padding[0]}px ${padding[1]}px ${padding[2]}px`;
};
const getUiLabelTransform = (active: boolean, padding: number[], labelTransform: number[]) => {
  const transformX = padding[1] + 1; // add one pixel as the label gets a bit offset when scaled down
  const transformY = labelTransform[active ? 1 : 0];
  const scale = active ? 0.7 : 1;
  return `translate(${transformX}px, ${transformY}px) scale(${scale})`;
};

interface IUiLabelConfig {
  width: string;
  transform: string;
}
export const getUiLabelConfig = (size: UiControlSize, active: boolean) => {
  const { padding, labelTransform } = inputProps[size] || inputProps[UiControlSize.Default];
  return {
    width: `calc(100% - ${padding[1] * 2}px)`,
    transform: getUiLabelTransform(active, padding, labelTransform),
  } as IUiLabelConfig;
};

export const getInputConfig = ({ size, animated }: IStyledInputProps) => {
  const props = inputProps[size] || inputProps[UiControlSize.Default];
  return {
    height: props.height + 'px',
    padding: getInputPadding(size, !!animated),
    fontSize: props.fontSize + 'rem',
    lineHeight: animated ? '1' : props.fontSize + 'px',
    borderWidth: props.borderWidth + 'px',
    borderRadius: props.borderRadius + 'px',
  } as IInputConfig;
};
