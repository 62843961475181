import React from 'react';
import styled from 'styled-components/macro';
import { INavItem } from '../navigation/navigation.types';
import { H4, Secondary } from '../ui/atoms';
import { StyledLinkDecent } from '../ui/components/StyledLink';
import { DeviceType } from '../ui/config';
import { FlexCol } from '../ui/modules/grid';
import { useI18n } from '../i18n/I18nContext';
import { ShowMoreLink } from '../ui/components/ShowMoreLink';

interface ICategoryGridItemProps {
  category: INavItem;
}

export const CategoryGridItem: React.FC<ICategoryGridItemProps> = ({ category }) => {
  const { t } = useI18n('category.CategoryGridItem');
  const link = `/category/${category.Id}`;

  return (
    <FlexCol width="100%" responsiveWidth={{ [DeviceType.Mobile]: '50%', [DeviceType.Laptop]: '25%' }}>
        <CategoryTitle>
          <StyledLinkDecent noUnderline to={link}>
            {category.Name}
          </StyledLinkDecent>
        </CategoryTitle>
        <CategoryDescription>
          {!!category?.Description?.trim().length && category.Description}

          <ShowMoreLink asBlock to={link} className="m-top_xs">
            {t('btn_showArticles')}
          </ShowMoreLink>
        </CategoryDescription>
    </FlexCol>
  );
};

const CategoryTitle = styled(H4)`
  margin-bottom: 0.3rem;
`;

const CategoryDescription = styled(Secondary)`
  font-size: 0.9rem;
  line-height: 1.5em;
`;
