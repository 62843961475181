import { urlApi } from '../config';
import { Language } from '../i18n/i18n.types';
import { HelpcenterResource } from '../resource/HelpcenterResource';
import { IArticleRatingGetDTO, IArticleRatingPostDTO, ICommentDTO } from './ratings.types';

const { fetchJson, postJson } = HelpcenterResource;

const _read = (lang: Language, pw: string) =>
  fetchJson(`${urlApi}/ratings/getArticleRatings/`, lang, { headers: { Authorization: pw } }) as Promise<IArticleRatingGetDTO[]>;

const writeRating = (lang: Language, rating: IArticleRatingPostDTO) =>
  postJson(`${urlApi}/ratings/postArticleRating`, lang, { body: JSON.stringify(rating) });

const writeFeedback = (lang: Language, comment: ICommentDTO) =>
  postJson(`${urlApi}/ratings/postFeedback`, lang, { body: JSON.stringify(comment) });

export const RatingResource = { read: _read, writeRating, writeFeedback };
