import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { NavigationResource } from './NavigationResource';
import NavSkeleton from './NavSkeleton';
import SkeletonContainer from '../skeleton/SkeletonContainer';
import { EmptyState, H4 } from '../ui/atoms';
import { color } from '../ui/config';
import { transition } from '../ui/helper';
import { StyledLinkDecent } from '../ui/components/StyledLink';
import { LinkListItem } from '../ui/components/LinkList';
import { INavItem } from './navigation.types';
import { useI18n } from '../i18n/I18nContext';
import { NavIcon, NavToggle } from '../ui/components/PageHeader';

interface SidenavProps {
  visible: boolean;
  setVisible: (val: boolean) => void;
}

export const Sidenav: React.FC<SidenavProps> = ({ visible, setVisible }) => {
  const [navData, setNavData] = useState([] as INavItem[]);
  const { t, lang } = useI18n('navigation.Sidenav');

  const closeMenu = () => setVisible(false);

  useEffect(() => {
    NavigationResource.read(lang).then(setNavData);
  }, [lang]);

  return (
    <SidenavWrapper withDesktop={visible}>
      <Overlay visible={visible} onClick={closeMenu}></Overlay>
      <SidenavBox visible={visible}>
        <NavToggle onClick={closeMenu}>
          <NavIcon className="fa fa-times" />
          {t('btn_close')}
        </NavToggle>

        <SkeletonContainer skeleton={<NavSkeleton />} showContent={!!navData.length}>
          {navData?.map(item => (
            <div key={item.Id} className="m-bottom m-top">
              <H4>
                <StyledLinkDecent noUnderline to={`/category/${item.Id}`} onClick={closeMenu}>
                  {item.Name}
                </StyledLinkDecent>
              </H4>
              {item?.Children?.length ? (
                <div>
                  {item.Children.map(i => (
                    <LinkListItem key={i.Id} to={`/helppage/${i.Id}`} onClick={closeMenu} noUnderline>
                      {i.Name}
                    </LinkListItem>
                  ))}
                </div>
              ) : (
                <EmptyState className="m-top_sm">{t('emptyState')}</EmptyState>
              )}
            </div>
          ))}
        </SkeletonContainer>
      </SidenavBox>
    </SidenavWrapper>
  );
};

const fxDuration = '.3s';

const SidenavWrapper = styled.nav<{ withDesktop?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  z-index: 8000;
`;

const Overlay = styled.div<{ visible?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);

  opacity: 0;
  visibility: hidden;
  ${transition(fxDuration, 'opacity', 'visibility')};

  ${props =>
    props.visible &&
    css`
      opacity: 1;
      visibility: visible;
    `};
`;

const SidenavBox = styled.aside<{ visible?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  width: 350px;
  height: 100%;
  padding: 2rem;
  overflow-y: auto;

  background: ${color.text.light};
  transform: translateX(100%);
  transition: transform ${fxDuration};
  ${props =>
    props.visible &&
    css`
      transform: translateX(0);
    `};
`;