export const color = {
  brand: {
    primary: 'hsl(218, 100%, 30%)',
    primary_light: 'hsl(215, 100%, 94%)', // used for link default state
    primary_hover: 'hsl(215, 100%, 86%)', // used for link hover state
    primary_dark: 'hsl(222, 100%, 22%)', // used for btn hover state
  },
  text: {
    primary: 'hsl(0, 0%, 20%)', // hsl(218, 2%, 11%),
    secondary: 'hsl(212, 3%, 42%)',
    meta: 'hsl(212, 3%, 63%)',
    light: 'hsl(0, 0%, 100%)',
  },
  grey: {
    light: 'hsl(218, 6%, 93%)',

    bg: 'hsl(218, 11%, 96%)',
    bg_hover: 'hsl(218, 11%, 89%)',
    border: 'hsl(218, 7%, 88%)',
    border_focus: 'hsl(218, 15%, 79%)',
  },
  skeleton: {
    base: 'hsl(218, 12%, 93%)',
    shimmer: 'hsl(218, 80%, 2%)',
  },
  icon: {
    meta: 'hsl(212, 3%, 67%)',
  },
  ui: {
    success: 'hsl(94, 73%, 49%)',
    error: 'hsl(358, 100%, 50%)',
  },
};

export enum DeviceType {
  Mobile = '480px',
  Tablet = '720px',
  Laptop = '1200px',
}

export const gridSpacingH = '1.25rem';
export const gridSpacingV = '1rem';
