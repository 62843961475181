import React from 'react';
import styled, { css } from 'styled-components/macro';
import { color } from '../config';
import { transition } from '../helper';
import { Button } from '../modules/controls/Button';
import { UiControlSize } from '../modules/controls/controls.types';
import { FlexRow } from '../modules/grid';

interface IModalProps {
  open: boolean;
  onClose: () => void;
}

export const Modal: React.FC<IModalProps> = ({ open, onClose, children }) => {
  return (
    <ModalContainer open={open}>
      <ModalBg onClick={onClose} />
      <ModalContent open={open}>
        {children}
      </ModalContent>
    </ModalContainer>
  );
};

export const ModalButtons = styled(FlexRow)`
  margin-top: 1rem;
`;

export const ModalButton = styled(Button).attrs({ size: UiControlSize.Small })`
  margin-right: 0.5rem;
`;

const ModalContainer = styled.div<{ open: boolean }>`
  display: grid;
  place-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8000;

  opacity: 0;
  visibility: hidden;
  ${transition('.2s', 'opacity', 'visibility')};

  ${props =>
    props.open &&
    css`
      opacity: 1;
      visibility: visible;
    `};
`;

const ModalBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
`;

const ModalContent = styled.div<{ open: boolean }>`
  padding: 2rem;
  width: 90vw;
  max-width: 760px;
  max-height: 90vh;
  overflow: auto;

  border-radius: 2px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1), 0 2px 10px rgba(0, 0, 0, 0.2);
  background: ${color.text.light};

  transform: translateY(0.5rem) scale(0.98);
  transition: transform 0.2s;

  ${props =>
    props.open &&
    css`
      transform: translateY(0) scale(1);
    `};
`;
