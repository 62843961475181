import React, { useEffect, useState } from 'react';
import { Hero } from './Hero';
import { NavigationResource } from '../navigation/NavigationResource';
import { LandingpageResource } from './LandinpageResource';
import useErrorRedirect from '../error-pages/useErrorRedirect';
import SkeletonContainer from '../skeleton/SkeletonContainer';
import LandingpageSkeleton from './LandingpageSkeleton';
import { Container, FlexCol, FlexRow, Section } from '../ui/modules/grid';
import { DeviceType } from '../ui/config';
import { H2 } from '../ui/atoms';
import styled from 'styled-components/macro';
import { AspectRatioContent, AspectRatioWrapper, media } from '../ui/helper';
import { INavItem } from '../navigation/navigation.types';
import { CategoryGridItem } from '../category/CategoryGridItem';
import { ILandingpageDTO } from './landingpage.types';
import { setDocumentTitle } from '../core/util';
import { useI18n } from '../i18n/I18nContext';
import { HelppageLink } from '../helppage/HelppageLink';
import VideoComponent from '../helppage/components/VideoComponent';

interface ILandingpageViewProps {}

const LandingpageView: React.FC<ILandingpageViewProps> = () => {
  const [navData, setNavData] = useState<INavItem[]>([]);
  const [lpData, setLpData] = useState<ILandingpageDTO>({} as ILandingpageDTO);
  const { t, lang } = useI18n('landingpage.LandingpageView');
  const redirectToErrorPage = useErrorRedirect();

  setDocumentTitle(t('pageTitle'), true);

  useEffect(() => {
    NavigationResource.read(lang).then((data) => setNavData(data));
  }, [lang]);

  useEffect(() => {
    LandingpageResource.read(lang)
      .then((data: ILandingpageDTO) => {
        if (!data) return redirectToErrorPage(500);
        setLpData(data);
      })
      .catch(() => setLpData({ VideoUrl: '', RecommendedPages: [] }));
  }, [lang, redirectToErrorPage]);

  return (
    <SkeletonContainer skeleton={<LandingpageSkeleton />} showContent={!!lpData.RecommendedPages}>
      <Hero title={t('headline_welcome')} searchHint={t('placeholder_search')} />

      <Container>
        <Section first>
          <FlexRow explicitCols flexWrap>
            {lpData.VideoUrl && (
              <FlexCol width="100%" responsiveWidth={{ [DeviceType.Tablet]: '50%' }}>
                <H2 spaced>{t('headline_intro')}</H2>
                <VideoWrapper>
                  <AspectRatioContent>
                    <VideoComponent video={lpData.VideoUrl} title="Schweitzer Mediacenter Film - Vorteile und FAQs" />
                  </AspectRatioContent>
                </VideoWrapper>
              </FlexCol>
            )}
            <FlexCol width="100%" responsiveWidth={{ [DeviceType.Tablet]: '50%' }}>
              <H2 spaced>{t('headline_faq')}</H2>
              <div className="m-top">
                {lpData?.RecommendedPages?.map((i) => (
                  <HelppageLink key={i.Id} link={i} />
                ))}
              </div>
            </FlexCol>
          </FlexRow>
        </Section>

        <Section>
          <H2 spaced>{t('headline_topics')}</H2>
          <FlexRow explicitCols flexWrap>
            {navData?.map((i) => (
              <CategoryGridItem key={i.Id} category={i} />
            ))}
          </FlexRow>
        </Section>
      </Container>
    </SkeletonContainer>
  );
};

// use AspectRatio of 16:9
const VideoWrapper = styled(AspectRatioWrapper).attrs({ height: '56.25%' })`
  margin-bottom: 3rem;

  ${media(DeviceType.Mobile)} {
    margin-bottom: 0;
  }
`;

export default LandingpageView;
